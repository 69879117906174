
import { ref, computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { useAuth, useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import TheButton from '@/components/TheButton.vue'
import TheLogo from '@/components/TheLogo.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import { settings, createOrUpdateSetting } from '@/services/userSettings'

export default defineComponent({
  name: 'Sidebar',

  components: {
    TheButton,
    TheLogo,
    SidebarMenu,
    LanguageSelect,
  },

  setup() {
    const { tenant } = useTenant()
    const auth = useAuth()
    const router = useRouter()
    const isExpanded = ref(true)
    const userName = computed(() => auth.user.value?.name.givenName)
    const closeMenu = (to: string): void => {
      router.push(to)
      document.querySelector('.app').classList.remove('show-menu')
    }
    const logout = (): void => {
      auth.logout()
    }

    const { locale } = useI18n()
    const currentLanguage = computed(() => locale.value)

    const languageList = computed(() =>
      tenant.value.langs.map((lang) => {
        return {
          value: lang.code,
          label: lang.label,
        }
      }),
    )

    const onLanguageChange = async (lang: string) => {
      locale.value = lang
      await createOrUpdateSetting(settings.lang, lang)
    }

    return {
      isExpanded,
      userName,
      closeMenu,
      logout,
      languageList,
      onLanguageChange,
      currentLanguage,
    }
  },
})
