import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-topbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheButton = _resolveComponent("TheButton")!
  const _component_LanguageSelect = _resolveComponent("LanguageSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheButton, {
      class: "c-topbar__hamburger",
      icon: "IconHamburger",
      onClick: _ctx.toggleMenu
    }, null, 8, ["onClick"]),
    _createVNode(_component_LanguageSelect, {
      "language-list": _ctx.languageList,
      currentLanguage: _ctx.currentLanguage,
      canClear: false,
      canDeselect: false,
      onLanguageChange: _ctx.onLanguageChange
    }, null, 8, ["language-list", "currentLanguage", "onLanguageChange"]),
    _createVNode(_component_TheButton, {
      class: "c-topbar__logout",
      icon: "IconLogout",
      onClick: _ctx.logout
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('LOGOUT')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}