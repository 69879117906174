import { ref } from 'vue'
import { getAssetsAll, getAsset } from './services/assets'
import { apiResponseItemsFilter } from './utils'
import useTenant from './useTenant'

const { tenant } = useTenant()

const assets = ref([])
const fetchAssets = async (query, options = {}) => {
  const namespace = options.namespace || tenant.value.namespace
  const res = await getAssetsAll(namespace, query)

  assets.value = apiResponseItemsFilter(res.data, assets.value)
}
const fetchAsset = async (id, options = {}) => {
  const namespace = options.namespace || tenant.value.namespace
  const res = await getAsset(namespace, id)

  assets.value = apiResponseItemsFilter([res], assets.value)
}
const getAssetById = id => {
  return assets.value.filter(obj => obj.id === id)[0]
}

export default () => {
  return {
    assets,
    fetchAsset,
    fetchAssets,
    getAssetById
  }
}
