
import { defineComponent, computed } from 'vue'
import TheButton from '@/components/TheButton.vue'
import { useAuth, useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import LanguageSelect from '@/components/LanguageSelect.vue'
import { settings, createOrUpdateSetting } from '@/services/userSettings'

export default defineComponent({
  components: {
    TheButton,
    LanguageSelect,
  },

  setup() {
    const { logout } = useAuth()
    const { tenant } = useTenant()
    const toggleMenu = () => {
      document.querySelector('.app').classList.toggle('show-menu')
    }
    const { locale } = useI18n()
    const currentLanguage = computed(() => locale.value)

    const languageList = computed(() =>
      tenant.value.langs.map((lang) => {
        return {
          value: lang.code,
          label: lang.label,
        }
      }),
    )

    const onLanguageChange = async (lang: string) => {
      locale.value = lang
      await createOrUpdateSetting(settings.lang, lang)
    }

    return {
      toggleMenu,
      logout,
      languageList,
      onLanguageChange,
      currentLanguage,
    }
  },
})
