
import { ref, onMounted, defineComponent } from 'vue'
import { useAssets } from '@/core'
export default defineComponent({
  name: 'RRCFile',

  props: {
    data: Object,
  },
  setup(props) {
    const { fetchAsset, getAssetById } = useAssets()
    const fileUrl = ref('')

    onMounted(async () => {
      await fetchAsset(props.data.content.assetId)
      const file = getAssetById(props.data.content.assetId)
      fileUrl.value = file.url
    })

    return {
      fileUrl,
    }
  },
})
