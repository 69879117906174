import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-filter-themes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_FieldsetRadio = _resolveComponent("FieldsetRadio")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createVNode(_component_TheHeading, { class: "c-filter-themes__heading" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('FILTERS_HEADING')), 1)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersList, (filterCategory) => {
      return (_openBlock(), _createBlock(_component_FieldsetRadio, {
        class: "c-filter-themes__fieldset",
        key: filterCategory.id,
        legend: _ctx.$t(`FILTERS_${filterCategory.slug.toUpperCase()}`),
        filterCategory: filterCategory,
        onFilterChange: _ctx.onFilterChange
      }, null, 8, ["legend", "filterCategory", "onFilterChange"]))
    }), 128))
  ]))
}