<template>
  <div class="c-video">
    <div class="c-video__wrapper">
      <div v-if="video" class="c-video__wrapper">
        <VVideoPlayer
          ref="thePlayer"
          :video-url="video.url"
          :video-type="video.mimetype"
          :text-tracks="textTracks"
          :video-attributes="videoAttributes"
          :options="options"
          :chapters="chapters"
          @timeupdate="onTimeUpdate"
        />
      </div>
      <div class="c-video-player__chapters">
        <VVideoChapter
          v-for="chapter in chapters"
          :key="chapter.name"
          :title="chapter.name"
          :start="chapter.start"
          :end="chapter.end"
          :currentTime="currentTime"
          @click="updateCurrentTime(chapter.start)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onBeforeMount, defineComponent } from 'vue'
import VVideoPlayer from '@forlagshuset/v-video-player-vjs'
import VVideoChapter from '@forlagshuset/v-video-chapter'
import { useAssets } from '@/core'

export default defineComponent({
  name: 'Video',

  components: {
    VVideoPlayer,
    VVideoChapter,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { fetchAsset, getAssetById } = useAssets()
    const thePlayer = ref(null)
    const video = ref(null)
    const currentTime = ref(0)
    const textTracks = ref(null)
    const chapters = props.data.content.chapters.map((mc) => {
      mc.start = mc.startTime
      mc.end = mc.endTime

      return mc
    })
    const videoAttributes = ref({
      crossorigin: '',
      playsinline: '',
      controls: '',
    })
    const options = {
      playbackRates: [0.5, 1, 1.5, 2],
      language: 'nb',
      skipTimeForward: 15,
      skipTimeBackward: 15,
    }

    onBeforeMount(async () => {
      await fetchAsset(props.data.content.videoId)
      if (props.data.content.textTracks.length) {
        await Promise.all(
          props.data.content.textTracks.map(async (tt) => {
            await fetchAsset(tt.textTrackId)
          }),
        )
      }

      textTracks.value = props.data.content.textTracks.map((tt) => {
        tt.src = getAssetById(tt.textTrackId).url

        return tt
      })
      video.value = getAssetById(props.data.content.videoId)
    })

    watch(video, (val) => {
      if (val.cover) {
        videoAttributes.value.poster = val.cover
      }
    })

    const onTimeUpdate = (time) => {
      currentTime.value = time
    }
    const updateCurrentTime = (time) => {
      thePlayer.value.player.currentTime(time)
      thePlayer.value.player.play()
    }

    return {
      textTracks,
      chapters,
      video,
      options,
      currentTime,
      videoAttributes,
      onTimeUpdate,
      updateCurrentTime,
      thePlayer,
    }
  },
})
</script>
