<template>
  <label class="c-radio-button">
    <input
      class="c-radio-button__input"
      v-model="model"
      type="radio"
      :name="name"
      :value="value"
    />
    <div class="c-radio-button__wrapper">
      <IconRadioButton class="c-radio-button__icon" />
      <div class="c-radio-button__label">{{ label }}</div>
    </div>
  </label>
</template>

<script>
import { computed, defineComponent } from 'vue'
import IconRadioButton from '@/assets/svg/IconRadioButton.vue'

export default defineComponent({
  name: 'TheRadioButton',

  components: {
    IconRadioButton,
  },

  props: {
    label: String,
    name: String,
    value: String,
    modelValue: String,
  },

  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        return emit('update:modelValue', value)
      },
    })

    return {
      model,
    }
  },
})
</script>

<style lang="scss">
.c-radio-button {
  position: relative;
  display: block;
  overflow: hidden;
}

.c-radio-button__input {
  position: absolute;
  top: 0;
  left: -3rem;
  width: 3rem;
  height: 3rem;

  &:focus {
    outline: none !important;
  }
}

.c-radio-button__wrapper {
  display: inline-flex;
  padding: 0.25rem;
  min-height: 3rem;

  .c-radio-button__input:focus + & {
    padding: 0;
    border: 0.25rem dashed $a11y-outline;
  }
}

.c-radio-button__icon {
  margin: auto;
  padding-left: 1rem;
  width: 1.25rem;
  height: 1.25rem;
}

.c-radio-button__label {
  font-size: 1.125rem;
  margin: auto 0 auto 0.5rem;
  padding-right: 1rem;
}
</style>
