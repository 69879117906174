
import { defineComponent } from 'vue'
import useModal from '@/composables/useModal'
import ModalWrapper from '@/components/ModalWrapper.vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import FilterStudents from '@/components/FilterStudents.vue'

export default defineComponent({
  name: 'ModalDashboardTheme',

  components: {
    ModalWrapper,
    TheHeading,
    TheButton,
    FilterStudents,
  },

  setup() {
    const { isModalOpen, modalContent, closeModal } = useModal()

    return {
      isModalOpen,
      closeModal,
      modalContent,
    }
  },
})
