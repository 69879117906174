import { useRouter } from 'vue-router'

const router = useRouter()

const DEFAULT_HEADERS = {
  headers: {
    'Content-Type': 'application/json'
  }
}

const fetchWithAuth = (path, options) => {
  const mergedOptions = Object.assign({}, DEFAULT_HEADERS, options)

  mergedOptions.headers.Authorization = 'Bearer ' + localStorage.getItem('token')

  return fetch(`${process.env.VUE_APP_COSS_URL}/${path}`, mergedOptions)
}

const fetchSH = async (param, options) => {
  const res = await fetchWithAuth(param, options)

  if (res.ok) {
    if (res.status === 204) {
      return res.text()
    }

    return res.json()
  }

  if (res.status === 401) {
    localStorage.clear()
    router.push('/')
  }
  const err = await res.json()
  throw new Error(err.message)
}

export {
  fetchWithAuth,
  fetchSH
}
