import { ref } from 'vue'
import jwtDecode from 'jwt-decode'

import auth from './auth'

const teacherRoles = ['teacher', 'institution', 'institution-owner', 'institution-manager', 'organization', 'admin', 'internal']
const user = ref(null)
const userRole = ref(null)
const token = ref(null)
const instance = () => auth
const logout = () => {
  const redirectUrl = `${auth.logoutUrl}&token=${encodeURIComponent(auth.token)}`

  auth.logout(redirectUrl)
}
const login = () => {
  auth.authorize({
    'redirectUrl': window.location.origin
  })
}
const setToken = () => {
  token.value = auth.token
}
const setUser = () => {
  const token = localStorage.getItem('token')

  user.value = auth.getUser()
  if (token) {
    const isTeacher = teacherRoles.some(tr => tr === jwtDecode(token).primary_role)
    userRole.value = isTeacher ? 'teacher' : 'student'
    setUserRoleAttrToBody(userRole.value)
  }
}

const setUserRoleAttrToBody = role => {
  document.body.dataset.userRole = role
}

export default () => {
  return {
    user,
    token,
    userRole,
    setUser,
    setToken,
    login,
    logout,
    instance,
    setUserRoleAttrToBody
  }
}
