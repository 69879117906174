import XAPI, { Actor, Statement, StatementObject } from '@xapi/xapi'
import { useAuth } from '@/core'

const { user } = useAuth()

const actor = (id?: string): Actor => ({
  objectType: 'Agent',
  account: {
    name: id || user.value?.providerId,
    homePage: 'https://eportal.fagbokforlaget.no',
  },
})
const generateStatement = (
  result: {
    success: boolean
    completion: boolean
    score: {
      scaled: number
    }
  },
  object: StatementObject,
): Statement => {
  const statement = {} as Statement

  statement.actor = actor()
  statement.verb = XAPI.Verbs[result.completion ? 'COMPLETED' : 'PROGRESSED']
  statement.result = result
  statement.object = object

  return statement
}

export { generateStatement, actor }
