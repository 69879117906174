import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_TheButton = _resolveComponent("TheButton")!
  const _component_FilterStudents = _resolveComponent("FilterStudents")!
  const _component_ModalWrapper = _resolveComponent("ModalWrapper")!

  return (_ctx.modalContent)
    ? (_openBlock(), _createBlock(_component_ModalWrapper, { key: 0 }, {
        content: _withCtx(() => [
          _createVNode(_component_TheHeading, { class: "c-modal__top-heading" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.modalContent.content.subject), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.modalContent.content.gripLevel) + " / " + _toDisplayString(_ctx.modalContent.content.theme), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_TheButton, {
            class: "c-modal-concept__top-close",
            icon: "IconClose",
            onClick: _ctx.closeModal
          }, null, 8, ["onClick"]),
          _createElementVNode("section", null, [
            _createVNode(_component_FilterStudents),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('DASHBOARD_STUDENTS_LIST_HEADING')), 1)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}