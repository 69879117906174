
import { computed, onBeforeMount, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useStructures } from '@/core'
import xapi from '@/core/services/lrs'
import { actor } from '@/lrs'
import useAccessCheck from '@/composables/useAccessCheck'
import useModal from '@/composables/useModal'
import ModalWrapper from '@/components/ModalWrapper.vue'
import TheButton from '@/components/TheButton.vue'
import FilterThemes from '@/components/FilterThemes.vue'
import DataGrid from '@/components/DataGrid.vue'

export default defineComponent({
  name: 'ModalDashboardStudent',

  components: {
    ModalWrapper,
    TheButton,
    FilterThemes,
    DataGrid,
  },

  setup() {
    const { isModalOpen, modalContent, closeModal } = useModal()
    const { t } = useI18n()
    const exercisesSlugPath = 'grip/oppgaver'
    const {
      fetchStructuresNode,
      fetchStructuresChildren,
      getStructuresBySlugPath,
      getStructuresChildrens,
    } = useStructures()
    const groupProducts = computed(() => {
      const products = []

      modalContent.value?.content.student.accessGroups.forEach((ag) => {
        ag.products.forEach((product) => {
          if (!products.some((p) => p.id === product.id)) {
            products.push(product)
          }
        })
      })

      return products
    })
    const { groupAcccessProductsCheck } = useAccessCheck()
    const exercisesParent = computed(() =>
      getStructuresBySlugPath(exercisesSlugPath),
    )
    const availableExercises = computed(() =>
      getStructuresChildrens(exercisesParent.value.id, 'DIRECTORY')
        .filter((subject) =>
          groupAcccessProductsCheck(subject.id, groupProducts.value),
        )
        .map((subject) => {
          subject.children = getStructuresChildrens(subject.id, 'DIRECTORY')
          subject.children.map((gripLevel) => {
            gripLevel.children = getStructuresChildrens(
              gripLevel.id,
              'DIRECTORY',
            )
            return gripLevel
          })

          return subject
        }),
    )
    const LRSProgressByExercise = ref([])
    const mapRows = (gripLevels) => {
      const rows = []

      for (const gripLevel of gripLevels) {
        for (const [themeId, theme] of gripLevel.children.entries()) {
          rows.push({
            name: `${gripLevel.name} / ${themeId + 1}. ${theme.name}`,
            'level-1': resolveLevelProgress(theme.slugPath + '/niva-1'),
            'level-2': resolveLevelProgress(theme.slugPath + '/niva-2'),
            'level-3': resolveLevelProgress(theme.slugPath + '/niva-3'),
          })
        }
      }

      return rows
    }
    const resolveLevelProgress = (path: string) => {
      const item = LRSProgressByExercise.value.find((lrsp) =>
        lrsp.object.id.includes(path),
      )

      if (!item) {
        return ''
      }

      if (!item.result) {
        return '-'
      }

      if (item.result.completion && item.result.success) {
        return t('VIEW_DASHBOARD_COMPLETED')
      }

      return t('VIEW_DASHBOARD_IN_PROGRESS')
    }

    const mapColumns = (name) => {
      return [
        {
          key: 'name',
          label: name,
        },
        {
          key: 'level-1',
          label: `${t('DASHBOARD_GRID_LEVEL')} 1`,
        },
        {
          key: 'level-2',
          label: `${t('DASHBOARD_GRID_LEVEL')} 2`,
        },
        {
          key: 'level-3',
          label: `${t('DASHBOARD_GRID_LEVEL')} 3`,
        },
      ]
    }

    const selectedFilter = ref({
      subject: 'all',
      level: 'all',
    })

    const filteredThemes = computed(() =>
      availableExercises.value
        .filter((subject) => {
          if (selectedFilter.value.subject === 'all') {
            return subject
          }

          return subject.slug === selectedFilter.value.subject
        })
        .map((subject) => {
          let gripLevels = subject.children
          if (selectedFilter.value.level !== 'all') {
            gripLevels = subject.children.filter(
              (grip) => grip.slug === `grip-${selectedFilter.value.level}`,
            )
          }

          return {
            name: subject.name,
            rows: mapRows(gripLevels),
          }
        }),
    )

    const onFilterChange = (event: {
      category: string
      value: string
    }): void => {
      selectedFilter.value[event.category] = event.value
    }

    onBeforeMount(async () => {
      await fetchStructuresNode(exercisesSlugPath)
      await fetchStructuresChildren(exercisesSlugPath, {
        limit: 1000,
        filter: `{"type": "DIRECTORY"}`,
      })

      await Promise.all(
        availableExercises.value.map(async (subject) => {
          if (subject.type === 'DIRECTORY') {
            await fetchStructuresChildren(subject.slugPath, { limit: 100 })
          }
          return subject
        }),
      )

      await Promise.all(
        availableExercises.value.map(async (subject) => {
          await Promise.all(
            await subject.children.map(async (gripLevel) => {
              await fetchStructuresChildren(gripLevel.slugPath, { limit: 100 })

              return gripLevel
            }),
          )

          return subject
        }),
      )
      selectedFilter.value.subject = availableExercises.value[0].slug
    })

    watch(
      () => selectedFilter.value.subject,
      () => {
        const levels = ['niva-1', 'niva-2', 'niva-3']
        const currentSubject = availableExercises.value.find(
          (ae) => ae.slug === selectedFilter.value.subject,
        )
        // fetch once when modal is open
        if (
          resolveLevelProgress(
            `${currentSubject.children[0].children[0].slugPath}/${levels[0]}`,
          )
        )
          return

        currentSubject.children.forEach((aec) => {
          aec.children.forEach((aecc) => {
            levels.forEach(async (l) => {
              const levelData = await xapi().getStatements({
                agent: actor(modalContent.value.content.student.id),
                activity: `${process.env.VUE_APP_COSS_URL}/structures/${aecc.slugPath}/${l}`,
                limit: 1,
              })
              if (levelData.data?.statements?.length) {
                LRSProgressByExercise.value.push(levelData.data.statements[0])
              } else {
                // No progress stored
                LRSProgressByExercise.value.push({
                  object: {
                    id: `${process.env.VUE_APP_COSS_URL}/structures/${aecc.slugPath}/${l}`,
                  },
                })
              }
            })
          })
        })
      },
    )

    return {
      isModalOpen,
      closeModal,
      modalContent,
      availableExercises,
      filteredThemes,
      onFilterChange,
      mapColumns,
    }
  },
})
