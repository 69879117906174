import katex from 'katex'
import { markdownToHtml } from '../core/utils'

const renderKatex = (el) => {
  Array.from(el.querySelectorAll('.katex')).forEach((k) => {
    k.innerHTML = katex.renderToString(k.innerHTML, {
      throwOnError: false,
    })
  })
}
const mdHtml = {
  beforeMount: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
    renderKatex(el)
  },

  beforeUpdate: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
    renderKatex(el)
  },
}

export { mdHtml }
