import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-content-renderer" }
const _hoisted_2 = {
  key: 1,
  class: "c-content-renderer__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_ContentToC = _resolveComponent("ContentToC")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_TheHeading, {
          key: 0,
          class: "c-content-renderer__title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.contentItems && _ctx.contentItems.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.hasToc)
            ? (_openBlock(), _createBlock(_component_ContentToC, {
                key: 0,
                "parent-class": "c-content-renderer"
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["c-content-renderer__element", [`c-content-renderer__element--${item.contentType}`]]),
              key: item.id
            }, [
              (item.contentData)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mapComponent(item.contentType)), {
                    key: item.id,
                    data: item.contentData,
                    item: item.contentId
                  }, null, 8, ["data", "item"]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}