/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { ref, Ref } from 'vue'

// refresh variables
const refreshing = ref(false)
const registration = ref(null)
const updateExists = ref(false)

const initSWUpdateWatch = () => {
  // Listen for our custom event from the SW registration
  document.addEventListener('swUpdated', updateAvailable, {
    once: true,
  })

  // Prevent multiple refreshes
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing.value) return
    refreshing.value = true
    // Here the actual reload of the page occurs
    window.location.reload()
  })
}

// Store the SW registration so we can send it a message
// We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
// To alert the user there is an update they need to refresh for
const updateAvailable = (event) => {
  registration.value = event.detail
  updateExists.value = true
}

// Called when the user accepts the update
const refreshApp = () => {
  updateExists.value = false
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration.value || !registration.value.waiting) return
  // send message to SW to skip the waiting and activate the new SW
  registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
}

export default (): {
  updateExists: Ref<boolean>
  updateAvailable: (event: {
    detail: {
      waiting: boolean
    }
  }) => void
  refreshApp: () => void
  initSWUpdateWatch: () => void
} => ({
  updateExists,
  updateAvailable,
  refreshApp,
  initSWUpdateWatch,
})
