<template>
  <div
    v-if="isModalOpen"
    :class="`c-modal c-${modalComponent.toLowerCase()}`"
    @click.self="onBgClick"
  >
    <TheButton class="c-modal__btn-close" @click="closeModal">
      <div class="c-modal__btn-close-cross">
        <span class="c-modal__btn-close-cross-cross" />
        <span class="c-modal__btn-close-cross-cross" />
      </div>
    </TheButton>
    <component :is="modalComponent" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import useModal from '@/composables/useModal'
import TheButton from '@/components/TheButton.vue'
import ModalConcept from '@/components/ModalConcept.vue'
import ModalDashboardTheme from '@/components/ModalDashboardTheme.vue'
import ModalDashboardStudent from '@/components/ModalDashboardStudent.vue'
import ModalAction from '@/components/ModalAction.vue'

export default defineComponent({
  components: {
    TheButton,
    ModalConcept,
    ModalDashboardTheme,
    ModalDashboardStudent,
    ModalAction,
  },

  setup() {
    const { isModalOpen, modalCanEscape, modalComponent, closeModal } =
      useModal()
    const onBgClick = () => {
      if (!modalCanEscape.value) return

      closeModal()
    }

    return {
      isModalOpen,
      modalComponent,
      onBgClick,
      closeModal,
    }
  },
})
</script>

<style lang="scss">
.c-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: rem(32px) 0;
  background: $modal__outer-bg;
  z-index: 9999;
}
.c-modal__btn-close {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: rem(24px);
  height: rem(24px);
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  z-index: 9999;

  .c-modalmedia & {
    display: block;
  }
}
.c-modal__btn-close-cross {
  position: absolute;
  top: 0;
  right: 0;
  width: rem(24px);
  height: rem(24px);
}
.c-modal__btn-close-cross-cross {
  position: absolute;
  right: 0.625em;
  top: -0.075rem;
  transform: rotate(45deg);
  width: 0.05em;
  height: 1.5em;
  background-color: $modal__close-text;
  color: $modal__close-text;
  border: 1px solid $modal__close-text;
  cursor: pointer;
}
.c-modal__btn-close-cross-cross:last-child {
  transform: rotate(-45deg);
}
</style>
