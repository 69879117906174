<template>
  <Card
    v-if="concept"
    class="c-rrc-concept"
    :title="concept.name"
    :description="concept.description"
    :image-id="concept.cover"
    :item="concept"
    :actions="cardActions"
  />
</template>

<script>
import { computed, onMounted } from 'vue'

import useModal from '@/composables/useModal'
import Card from '@/components/Card'
import { useStructures } from '@/core'

export default {
  name: 'RRCConcept',

  components: {
    Card,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { setModal } = useModal()
    const { fetchStructuresNode, getStructuresById } = useStructures()
    const concept = computed(() => getStructuresById(props.data.value))
    const cardActions = {
      type: 'concept',
      action: (concept) => {
        onShowConcept(concept)
      },
    }
    const onShowConcept = (concept) => {
      setModal('concept', concept)
    }
    onMounted(async () => {
      if (!concept.value) {
        await fetchStructuresNode(props.data.value)
      }
    })

    return {
      concept,
      cardActions,
      onShowConcept,
    }
  },
}
</script>
