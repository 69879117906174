import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["aria-labelledby", "aria-label"]
const _hoisted_2 = { class: "c-data-grid__thead" }
const _hoisted_3 = ["aria-rowindex"]
const _hoisted_4 = ["aria-sort", "aria-colindex"]
const _hoisted_5 = ["role", "onClick", "onKeydown", "tabindex"]
const _hoisted_6 = { class: "c-data-grid__tbody" }
const _hoisted_7 = ["aria-rowindex", "onClick"]
const _hoisted_8 = ["tabindex", "onClick", "aria-colindex"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("table", {
    class: "c-data-grid",
    role: "grid",
    ref: "dataGrid",
    "aria-labelledby": _ctx.getAriaLabelledby,
    "aria-label": _ctx.ariaLabel,
    onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
  }, [
    _createElementVNode("thead", _hoisted_2, [
      _createElementVNode("tr", {
        class: "c-data-grid__tr c-data-grid__tr--thead",
        "aria-rowindex": _ctx.i + 1
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, i) => {
          return (_openBlock(), _createElementBlock("th", {
            key: col.key,
            class: _normalizeClass([
            'c-data-grid__th',
            `c-data-grid__th--${col.key}`,
            { active: _ctx.sortKey === col.key },
            { sortable: col.sortable },
          ]),
            "aria-sort": col.sortable && _ctx.colSortOrder(col.key),
            "aria-colindex": i + 1
          }, [
            _createElementVNode("span", {
              class: "c-data-grid__th-btn",
              role: col.sortable && 'button',
              onClick: ($event: any) => (col.sortable && (_ctx.onClickNode(0, i), _ctx.sortBy(col.key))),
              onKeydown: _withKeys(($event: any) => (col.sortable && _ctx.sortBy(col.key)), ["enter"]),
              tabindex: _ctx.focusableHeadCells && -1
            }, [
              _createTextVNode(_toDisplayString(col.label) + " ", 1),
              (col.sortable && _ctx.sortKey === col.key)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    icon: "IconArrow",
                    class: _normalizeClass(["c-data-grid__arrow", col.sortable && _ctx.colSortOrder(col.key)])
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true)
            ], 40, _hoisted_5)
          ], 10, _hoisted_4))
        }), 128))
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("tbody", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, i) => {
        return (_openBlock(), _createElementBlock("tr", {
          class: "c-data-grid__tr",
          key: i,
          "aria-rowindex": i + 2,
          onClick: ($event: any) => (_ctx.onClickRow(row))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, j) => {
            return (_openBlock(), _createElementBlock("td", {
              class: "c-data-grid__td",
              key: col.key,
              tabindex: _ctx.focusableRowCells && -1,
              onClick: ($event: any) => (_ctx.focusableRowCells && _ctx.onClickNode(i + 1, j)),
              "aria-colindex": j + 1
            }, [
              (_ctx.slots[col.key])
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _renderSlot(_ctx.$slots, col.key, { value: row })
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(row[col.key]), 1))
            ], 8, _hoisted_8))
          }), 128))
        ], 8, _hoisted_7))
      }), 128))
    ])
  ], 40, _hoisted_1))
}