<template>
  <div class="c-audio">
    <audio v-if="audioAsset" ref="player" controls @timeupdate="onTimeUpdate">
      <source :src="audioAsset.url" :type="audioAsset.mimetype" />
    </audio>
    <div class="c-audio__chapters">
      <VVideoChapter
        v-for="chapter in data.content.chapters"
        :key="chapter.name"
        :title="chapter.name"
        :start="chapter.startTime"
        :end="chapter.endTime"
        :currentTime="currentTime"
        @click="updateCurrentTime(chapter.startTime)"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import Plyr from 'plyr'
import VVideoChapter from '@forlagshuset/v-video-chapter'

import { useAssets } from '@/core'

export default {
  name: 'Audio',

  components: {
    VVideoChapter,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { fetchAsset, getAssetById } = useAssets()
    const audioAsset = computed(() => getAssetById(props.data.content.audioId))
    const player = ref(null)
    const plyr = ref(null)
    const currentTime = ref(0)
    const updateCurrentTime = (time) => {
      player.value.currentTime = time
      player.value.play()
    }
    const onTimeUpdate = (ev) => {
      currentTime.value = ev.target.currentTime
    }

    onMounted(() => {
      if (!audioAsset.value) {
        fetchAsset(props.data.content.audioId)
      }
    })

    watch(
      () => player.value,
      () => {
        plyr.value = new Plyr(player.value)
      },
    )

    return {
      audioAsset,
      player,
      updateCurrentTime,
      currentTime,
      onTimeUpdate,
    }
  },
}
</script>

<style lang="scss">
$plyr-color-main: $audio-player__main;
@import '~plyr/src/sass/plyr';

.plyr {
  &--audio {
    border: 1px solid $audio-player__border;
    border-radius: rem(10px);
    max-width: rem(600px);
    margin: rem(20px) 0;
  }
}

.c-button {
  .c-audio__chapters & {
    padding: rem(8px) rem(12px);
    margin: rem(6px);
  }
}
</style>
