
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useTenant } from '@/core'
import useAccessCheck from '@/composables/useAccessCheck'
import TheButton from '@/components/TheButton.vue'

export default defineComponent({
  name: 'SidebarMenu',

  components: {
    TheButton,
  },

  emits: ['close-menu'],

  setup(props, { emit }) {
    const { showProduct } = useAccessCheck()
    const { locale } = useI18n()
    const route = useRoute()
    const { tenant } = useTenant()
    const menu = computed(() => tenant.value?.menu[locale.value])
    const closeMenu = (to: string): void => {
      emit('close-menu', to)
    }

    return {
      menu,
      route,
      closeMenu,
      showProduct,
    }
  },
})
