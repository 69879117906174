import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-card__image"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "c-card__content" }
const _hoisted_4 = { class: "c-card__text" }
const _hoisted_5 = {
  key: 0,
  class: "c-card__pre-heading"
}
const _hoisted_6 = {
  key: 2,
  class: "c-card__description"
}
const _hoisted_7 = {
  key: 3,
  class: "c-card__duration"
}
const _hoisted_8 = {
  key: 1,
  class: "c-card__actions"
}
const _hoisted_9 = {
  key: 0,
  class: "c-card__actions-dbok"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 2,
  class: "c-card__actions-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_TheButton = _resolveComponent("TheButton")!
  const _component_CardExercisesLevels = _resolveComponent("CardExercisesLevels")!
  const _directive_md_html = _resolveDirective("md-html")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'c-card',
      `c-card--${_ctx.actions.type}`,
      {
        'c-card--has-link': _ctx.hasLink,
        'c-card__coming-soon': _ctx.actions.type === 'exercises' && !_ctx.description,
      },
    ])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cardWrapperTag), _mergeProps({ class: "c-card__wrapper" }, _ctx.cardWrapperAttrs, _toHandlers(_ctx.cardWrapperEvents)), {
      default: _withCtx(() => [
        (_ctx.image)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("img", {
                class: "c-card__image-image",
                src: _ctx.image.url,
                alt: _ctx.title
              }, null, 8, _hoisted_2)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.preHeading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.preHeading), 1))
              : _createCommentVNode("", true),
            (_ctx.title)
              ? (_openBlock(), _createBlock(_component_TheHeading, {
                  key: 1,
                  level: "h3",
                  class: "c-card__title"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.description && _ctx.actions.type !== 'exercises')
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, null, 512)), [
                  [_directive_md_html, _ctx.shorten(_ctx.description, 150)]
                ])
              : _createCommentVNode("", true),
            (_ctx.hasDuration)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 6 " + _toDisplayString(_ctx.$t('CARD_VIDEO_MINUTES')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.hasButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_ctx.actions.type === 'dbok')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
                      return (_openBlock(), _createBlock(_component_TheButton, {
                        key: button.id,
                        class: "c-card__btn",
                        href: `https://reader.dbok.no/#/book/${button.id}`,
                        tabindex: _ctx.hasLink ? '-1' : '0',
                        type: "dbok"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(button.text)), 1)
                        ]),
                        _: 2
                      }, 1032, ["href", "tabindex"]))
                    }), 128))
                  ]))
                : (_ctx.actions.type === 'exercises')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_CardExercisesLevels, { parent: _ctx.item }, null, 8, ["parent"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_TheButton, {
                        class: "c-card__btn",
                        type: _ctx.actions.type,
                        tabindex: _ctx.hasLink ? '-1' : '0'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                        ]),
                        _: 1
                      }, 8, ["type", "tabindex"])
                    ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16))
  ], 2))
}