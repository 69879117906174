import { useAuth, useTenant } from '@/core'
import { ComposeAccessCheck } from 'types/interface/AccessCheck'
import { ConfigEportalProduct, ConfigMenuItem } from 'types/interface/Config'
import { ref } from 'vue'

const auth = useAuth()
const { tenant } = useTenant()
const productsAvailable = ref([])
const productsChecked = ref(false)
const productsCheck = async (): Promise<void> => {
  const productIds = tenant.value.eportalProducts.map(
    (ep: ConfigEportalProduct): string => ep.id,
  )
  const { products } = await auth.instance().checkAccess(productIds)
  productsChecked.value = true
  tenant.value.eportalProducts.forEach((ep: ConfigEportalProduct): void => {
    if (products.some((p: { id: string }): boolean => p.id === ep.id)) {
      productsAvailable.value.push(ep)
    }
  })
}
const hasProductAccess = (products: string[]): boolean => {
  if (process.env.NODE_ENV === 'development') return true
  return products && products.length
    ? productsAvailable.value.some((pa: ConfigEportalProduct): boolean =>
        products.includes(pa.product),
      )
    : true
}

const hasRoleAccess = (roles: string[]): boolean => {
  return roles && roles.length ? roles.includes(auth.userRole.value) : true
}

const showProduct = (item: ConfigMenuItem): boolean => {
  return hasProductAccess(item.productAccess) && hasRoleAccess(item.roleAccess)
}

const showErudioProduct = (itemId: string): boolean => {
  if (process.env.NODE_ENV === 'development') return true
  const eportalProduct = tenant.value.eportalProducts.find(
    (p) => p.contentId === itemId,
  )
  return (
    eportalProduct &&
    productsAvailable.value.map((pa) => pa.id).includes(eportalProduct.id)
  )
}

const groupAcccessProductsCheck = (contentId, groupAccessProducts) =>
  groupAccessProducts.some(
    (gap) =>
      tenant.value.eportalProducts.find((ep) => ep.id === gap.id).contentId ===
      contentId,
  )

export default (): ComposeAccessCheck => ({
  productsAvailable,
  productsChecked,
  groupAcccessProductsCheck,
  productsCheck,
  showProduct,
  showErudioProduct,
})
