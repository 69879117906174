import { ref } from 'vue'
import { getStructuresAll, getStructuresChildren, getStructuresNode } from './services/structures'
import useLocalizations from './useLocalizations'
import { apiResponseItemsFilter, sortItemsByOrder } from './utils'
import useTenant from './useTenant'
import useContents from './useContents'

const { tenant } = useTenant()
const { getObjectById } = useContents()
const { getLocalizationById } = useLocalizations()
const structures = ref([])
const fetchStructuresParents = async (options = {}) => {
  const namespace = options.namespace || tenant.value.namespace
  const res = await getStructuresAll(namespace, {
    limit: 100,
    filter: `{"parent": null}`
  })
  res.data.map(s => {
    s.slugPath = s.slug

    return s
  })

  setStructure(res.data)
}
const fetchStructuresChildren = async (slugPath, query = { limit: 100 }, options = {}) => {
  const namespace = options.namespace || tenant.value.namespace
  const res = await getStructuresChildren(namespace, encodeURIComponent(slugPath), query)

  res.data.map(s => {
    s.slugPath = slugPath + `/${s.slug}`

    return s
  })

  setStructure(res.data)
}
const fetchStructuresNode = async (slugPath, options = {}) => {
  const namespace = options.namespace || tenant.value.namespace
  const res = await getStructuresNode(namespace, encodeURIComponent(slugPath))

  res.slugPath = slugPath

  setStructure([res])
}
const getStructuresBySlugPath = (slugPath, withChildrens = false, type = 'DIRECTORY', locale = '') => {
  const result = JSON.parse(JSON.stringify(structures.value.filter(s => slugPath === s.slugPath)))

  result.map(s => {
    if (locale) {
      s.name = getLocalizationById(s.id, locale, 'structure')?.content.name || s.name
      s.description = getLocalizationById(s.id, locale, 'structure')?.content.description || s.description
    }
    s.childrens = withChildrens ? getStructuresChildrens(s.id, type, locale) : []

    return s
  })

  return result[0] || {}
}

const getStructuresById = (id, withChildrens = false, type = 'DIRECTORY', locale = '') => {
  const result = JSON.parse(JSON.stringify(structures.value.filter(s => id === s.id)))

  result.map(s => {
    if (locale) {
      s.name = getLocalizationById(s.id, locale, 'structure')?.content.name || s.name
      s.description = getLocalizationById(s.id, locale, 'structure')?.content.description || s.description
    }
    s.childrens = withChildrens ? getStructuresChildrens(s.id, type, locale) : []

    return s
  })

  return result[0]
}

const getStructuresChildrens = (parentId, type, locale = '') => {
  const items = JSON.parse(JSON.stringify(sortItemsByOrder(structures.value.filter(s => {
    if (type) {
      return s.parent === parentId && s.type === type
    }

    return s.parent === parentId
  }))))

  items.map(item => {
    if (locale) {
      item.name = getLocalizationById(item.id, locale, 'structure')?.content.name || item.name
      item.description = getLocalizationById(item.id, locale, 'structure')?.content.description || item.description
    }
    if (item.type === 'OBJECT' && item.contentId) {
      item.contentData = getObjectById(item.contentId, locale)
    }

    return item
  })

  return items
}

const setStructure = (data) => {
  structures.value = apiResponseItemsFilter(data, structures.value)
}

export default () => {
  return {
    structures,
    fetchStructuresParents,
    fetchStructuresChildren,
    fetchStructuresNode,
    getStructuresBySlugPath,
    getStructuresById,
    getStructuresChildrens,
    setStructure,
  }
}
