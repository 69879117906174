<template>
  <Card
    v-if="file"
    class="c-rrc-file"
    :title="file.name"
    :description="file.description"
    :image-id="file.cover"
    :item="file"
    :actions="cardActions"
  />
</template>

<script>
import { computed, onMounted } from 'vue'

import Card from '@/components/Card'
import { useStructures, useContents, useAssets } from '@/core'

export default {
  name: 'RRCFiles',

  components: {
    Card,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { fetchStructuresNode, getStructuresById } = useStructures()
    const { getStructuresChildrens, fetchStructuresChildren } = useStructures()
    const { getObjectById, fetchObject } = useContents()
    const { fetchAsset } = useAssets()
    const file = computed(() => getStructuresById(props.data.value))
    const cardActions = {
      type: 'file',
      action: async (item) => {
        await fetchStructuresChildren(item.slugPath, {
          limit: 100,
          filter: `{"type": "OBJECT"}`,
        })
        getStructuresChildrens(item.id, 'OBJECT').forEach(async (sc) => {
          await fetchObject(sc.contentId)
          const obj = getObjectById(sc.contentId)
          fetchAsset(obj.content.assetId)
        })
      },
    }
    onMounted(async () => {
      if (!file.value) {
        await fetchStructuresNode(props.data.value)
      }
    })

    return {
      file,
      cardActions,
    }
  },
}
</script>
