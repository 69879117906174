import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "c-sidebar-menu" }
const _hoisted_2 = { class: "c-sidebar-menu__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheButton = _resolveComponent("TheButton")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["c-sidebar-menu__item", [item.class]]),
          key: item.title
        }, [
          (_ctx.showProduct(item))
            ? (_openBlock(), _createBlock(_component_TheButton, {
                key: 0,
                to: item.to,
                icon: item.icon,
                class: _normalizeClass({ 'router-link-active': _ctx.route.path.indexOf(item.to) !== -1 }),
                onClick: _withModifiers(($event: any) => (_ctx.closeMenu(item.to)), ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to", "icon", "class", "onClick"]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ])
  ]))
}