import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Topbar = _resolveComponent("Topbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app", [{ 'has-sidebar': _ctx.hasSidebar }, `route-${_ctx.routeName}`]])
  }, [
    (_ctx.hasSidebar)
      ? (_openBlock(), _createBlock(_component_Sidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasSidebar)
        ? (_openBlock(), _createBlock(_component_Topbar, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, {
        key: _ctx.$route.path
      })
    ]),
    _createVNode(_component_Modal)
  ], 2))
}