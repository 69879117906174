import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "c-modal-concept__wrapper" }
const _hoisted_2 = { class: "c-modal-concept__content-wrapper" }
const _hoisted_3 = ["dir"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_TheButton = _resolveComponent("TheButton")!
  const _component_ContentRenderer = _resolveComponent("ContentRenderer")!
  const _component_ModalWrapper = _resolveComponent("ModalWrapper")!

  return (_ctx.modalContent)
    ? (_openBlock(), _createBlock(_component_ModalWrapper, {
        key: 0,
        title: _ctx.modalContent.title
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TheHeading, { class: "c-modal__top-heading" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modalContent.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_TheButton, {
              class: "c-modal-concept__top-close",
              icon: "IconClose",
              onClick: _ctx.closeModal
            }, null, 8, ["onClick"]),
            _createElementVNode("div", _hoisted_2, [
              (_ctx.contentItems.length)
                ? (_openBlock(), _createBlock(_component_ContentRenderer, {
                    key: 0,
                    "content-items": _ctx.contentItems
                  }, null, 8, ["content-items"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([
              'c-modal-concept__translated-content',
              `c-modal-concept__translated-content--${_ctx.direction}`,
            ]),
                dir: _ctx.direction
              }, [
                (_ctx.conceptLocale && _ctx.translatedItems.length)
                  ? (_openBlock(), _createBlock(_component_ContentRenderer, {
                      key: 0,
                      "content-items": _ctx.translatedItems
                    }, null, 8, ["content-items"]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_3)
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}