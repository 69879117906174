import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "c-file-link",
    href: _ctx.fileUrl,
    target: "_blank",
    rel: "noopener noreferrer"
  }, _toDisplayString(_ctx.data.content.name), 9, _hoisted_1))
}