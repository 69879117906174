<template>
  <component :is="currentComponent" />
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'Icon',

  props: {
    icon: String,
  },

  setup(props) {
    const currentComponent = defineAsyncComponent(() =>
      import(`@/assets/svg/${props.icon}`),
    )

    return {
      currentComponent,
    }
  },
})
</script>
