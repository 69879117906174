import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-card-exercises-levels" }
const _hoisted_2 = {
  key: 0,
  class: "c-card-exercises-coming-soon"
}
const _hoisted_3 = {
  key: 2,
  class: "c-card-exercises-levels__list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheButton = _resolveComponent("TheButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.parent.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('COMING_SOON')), 1))
      : (_ctx.levels.length === 0)
        ? (_openBlock(), _createBlock(_component_TheButton, {
            key: 1,
            onClick: _ctx.loadLevels,
            type: "exercises"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.loading
          ? _ctx.$t('ARCHIVE_CARD_BUTTON_LOADING')
          : _ctx.$t('ARCHIVE_CARD_BUTTON_TEXT')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levels, (level) => {
              return (_openBlock(), _createElementBlock("li", {
                key: level.id,
                class: "c-card-exercises-levels__list-item"
              }, [
                _createVNode(_component_TheButton, {
                  to: level.to,
                  type: "exercises"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(level.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ]))
  ]))
}