import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-fieldset-radio__wrapper" }
const _hoisted_2 = { class: "c-fieldset-radio__legend" }
const _hoisted_3 = { class: "c-fieldset-radio__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheRadioButton = _resolveComponent("TheRadioButton")!

  return (_openBlock(), _createElementBlock("fieldset", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("legend", _hoisted_2, _toDisplayString(_ctx.legend), 1),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterCategory.filters, (filter) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "c-fieldset-radio__list-item",
            key: filter.id
          }, [
            _createVNode(_component_TheRadioButton, {
              class: "c-fieldset-radio__input",
              modelValue: _ctx.currentRadioChecked,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRadioChecked) = $event)),
              label: filter.name,
              name: _ctx.filterCategory.slug,
              value: filter.value,
              onChange: _ctx.onRadioChange
            }, null, 8, ["modelValue", "label", "name", "value", "onChange"])
          ]))
        }), 128))
      ])
    ])
  ]))
}