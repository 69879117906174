import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-related-resources" }
const _hoisted_2 = { class: "c-related-resources__wrapper" }
const _hoisted_3 = { class: "c-related-resources__group-wrapper" }
const _hoisted_4 = { class: "c-related-resources__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedResources, (group) => {
        return (_openBlock(), _createElementBlock("div", {
          key: group.title,
          class: _normalizeClass(["c-related-resources__group", [`c-related-resources__group--${group.type}`]])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_TheHeading, {
              level: "h3",
              class: "c-related-resources__title"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(group.title), 1)
              ]),
              _: 2
            }, 1024),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.value,
                  class: "c-related-resources__item"
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentName(group.type)), { data: item }, null, 8, ["data"]))
                ]))
              }), 128))
            ])
          ])
        ], 2))
      }), 128))
    ])
  ]))
}