
import { defineComponent, onBeforeMount, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default defineComponent({
  name: 'LanguageSelect',

  props: {
    languageList: String,
    currentLanguage: String,
    canClear: Boolean,
    canDeselect: Boolean,
    placeholder: String,
    label: String,
  },

  components: {
    Multiselect,
  },

  setup(props, { emit }) {
    const selected = ref('')
    onBeforeMount(() => {
      selected.value = props.currentLanguage as string
    })
    const onSelectChange = (event) => {
      emit('languageChange', event)
    }

    watch(
      () => props.currentLanguage,
      (parentLang) => {
        if (selected.value !== parentLang) {
          selected.value = parentLang as string
        }
      },
    )

    return {
      selected,
      onSelectChange,
    }
  },
})
