import { ComposeModal, ModalContent } from 'types/interface/Modal'
import { ref } from 'vue'

const MODAL_CAN_ESCAPE_INITIAL = true
const isModalOpen = ref(false)
const modalComponent = ref('')
const modalContent = ref(null)
const modalCanEscape = ref(MODAL_CAN_ESCAPE_INITIAL)
const onClose = (): void => {
  isModalOpen.value = false
  modalContent.value = null
  modalComponent.value = ''
  document.documentElement.style.overflow = 'auto'
  if (modalCanEscape.value) {
    window.removeEventListener('keydown', onEsc)
  }
  modalCanEscape.value = MODAL_CAN_ESCAPE_INITIAL
}
const onEsc = (ev: KeyboardEvent): void => {
  if (ev.key === 'Escape') {
    onClose()
  }
}
const setModal = (
  component: string,
  content: ModalContent,
  options: {
    canEscape: boolean
  } = {
    canEscape: MODAL_CAN_ESCAPE_INITIAL,
  },
): void => {
  isModalOpen.value = true
  modalComponent.value = component
  modalContent.value = content
  modalCanEscape.value = options.canEscape
  document.documentElement.style.overflow = 'hidden'
  if (modalCanEscape.value) {
    window.addEventListener('keydown', onEsc)
  }
}
const closeModal = (): void => {
  onClose()
}

export default (): ComposeModal => ({
  isModalOpen,
  modalComponent,
  modalContent,
  modalCanEscape,
  setModal,
  closeModal,
})
