
import { ref, defineComponent, onBeforeMount } from 'vue'
import TheRadioButton from '@/components/TheRadioButton.vue'

export default defineComponent({
  name: 'Filters',

  props: {
    category: Array,
    legend: String,
    filterCategory: Object,
  },

  components: {
    TheRadioButton,
  },

  setup(props, { emit }) {
    const currentRadioChecked = ref('all')

    const onRadioChange = () => {
      emit('filter-change', {
        category: props.filterCategory.slug,
        value: currentRadioChecked.value,
      })
    }

    onBeforeMount(() => {
      currentRadioChecked.value = props.filterCategory.filters[0].value
    })

    return {
      currentRadioChecked,
      onRadioChange,
    }
  },
})
