
import { ref, computed, defineComponent, onMounted } from 'vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import CardExercisesLevels from '@/components/CardExercisesLevels.vue'
import { utils, useAssets } from '@/core'
import { truncate } from '@/core/utils'

export default defineComponent({
  name: 'Card',

  components: {
    TheHeading,
    TheButton,
    CardExercisesLevels,
  },

  props: {
    preHeading: String,
    title: String,
    description: String,
    imageId: String,
    imageWidth: {
      type: Number,
      default: 400,
    },
    actions: Object,
    item: Object,
    hasButton: Boolean,
    buttonText: String,
    buttons: Array,
  },

  setup(props) {
    const image = ref(null)
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const cardWrapperTag = computed(() => {
      if (
        props.actions.type === 'interdisciplinary' ||
        props.actions.type === 'link' ||
        props.actions.type === 'tile' ||
        props.actions.type === 'video'
      ) {
        return 'router-link'
      }

      if (props.actions.type === 'concept') {
        return 'a'
      }

      return 'div'
    })
    const cardWrapperAttrs = computed(() => {
      if (
        props.actions.type === 'interdisciplinary' ||
        props.actions.type === 'link' ||
        props.actions.type === 'tile' ||
        props.actions.type === 'video'
      ) {
        return {
          to: props.actions.action(props.item),
        }
      }

      if (props.actions.type === 'concept') {
        return {
          href: '#',
        }
      }

      return {}
    })
    const cardWrapperEvents = computed(() => {
      if (props.actions.type === 'concept') {
        return {
          click: (ev) => {
            ev.preventDefault()
            props.actions.action(props.item)
          },
        }
      }

      return {}
    })
    const hasLink = computed(() => {
      const allowedTypes = [
        'interdisciplinary',
        'tile',
        'link',
        'concept',
        'video',
        'dbok',
      ]

      return allowedTypes.some((at) => at === props.actions.type)
    })
    const hasDuration = computed(() => {
      const allowedTypes = ['video']

      return allowedTypes.some((at) => at === props.actions.type) && false
    })
    const shorten = (text, limit) => {
      return truncate(text, limit)
    }

    onMounted(async () => {
      if (props.imageId) {
        if (props.actions.type === 'dbok') {
          image.value = {
            url: `https://dpm.fagbokforlaget.no/streams/${props.imageId}/cover`,
          }
        } else {
          await fetchAsset(props.imageId)
          image.value = getAssetById(props.imageId)
          image.value.url = addCloudinaryUrlParams(
            image.value.url,
            `w_${props.imageWidth}`,
          )
        }
      }
    })

    return {
      image,
      shorten,
      cardWrapperTag,
      cardWrapperAttrs,
      hasLink,
      hasDuration,
      cardWrapperEvents,
    }
  },
})
