
import { computed, defineComponent } from 'vue'
import { useTenant } from '@/core'
import TheHeading from '@/components/TheHeading.vue'
import FieldsetRadio from '@/components/FieldsetRadio.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'FilterThemes',

  props: {
    availableExercises: Array,
  },

  components: {
    TheHeading,
    FieldsetRadio,
  },

  setup(props, { emit }) {
    const { tenant } = useTenant()
    const { t } = useI18n()

    const subjects = computed(() => {
      return [
        ...(
          props.availableExercises as Array<{ name: string; slug: string }>
        ).map((subject) => {
          return {
            name: subject.name,
            value: subject.slug,
          }
        }),
      ]
    })

    const levels = computed(() => {
      return [
        {
          name: t('FILTERS_ALL'),
          value: 'all',
        },
        ...tenant.value?.filters.levels.map((level) => {
          return {
            name: `${level}`,
            value: level,
          }
        }),
      ]
    })

    const filtersList = computed(() => [
      ...(subjects.value.length > 1
        ? [
            {
              slug: 'subject',
              filters: subjects.value,
            },
          ]
        : []),
      {
        slug: 'level',
        filters: levels.value,
      },
    ])

    const onFilterChange = (event: {
      category: string
      value: string
    }): void => {
      emit('filter-change', event)
    }

    return {
      filtersList,
      onFilterChange,
    }
  },
})
