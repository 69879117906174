<template>
  <component
    :is="currentComponent"
    class="c-button"
    :class="[
      { 'c-button--icon': icon },
      `c-button--${currentComponent}`,
      `c-button--${type}`,
    ]"
    v-bind="attributes"
  >
    <Icon v-if="icon" :icon="icon" />
    <slot />
  </component>
</template>

<script>
import { computed, defineComponent } from 'vue'

import Icon from '@/components/Icon'

export default defineComponent({
  name: 'TheButton',

  components: {
    Icon,
  },

  props: {
    to: String,
    href: String,
    icon: String,
    type: String,
  },

  setup(props) {
    const currentComponent = computed(() => {
      if (props.to) {
        return 'router-link'
      } else if (props.href) {
        return 'a'
      } else {
        return 'button'
      }
    })
    const attributes = computed(() => {
      if (currentComponent.value === 'router-link') {
        return {
          to: props.to,
          exact: props.to === '/',
        }
      } else if (currentComponent.value === 'a') {
        return {
          href: props.href,
          target: '_blank',
        }
      } else {
        return {}
      }
    })

    return {
      attributes,
      currentComponent,
    }
  },
})
</script>

<style lang="scss">
.c-button {
  display: inline-block;
  text-decoration: none;

  &--button {
    @include btnReset();
  }

  &--icon {
    font-weight: 400;
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 0.9rem;
      min-width: 1.25rem;
    }

    &:hover,
    &.router-link-active:not([exact='true']),
    &.router-link-exact-active {
      font-weight: 600;
    }
  }

  &--exercises,
  &--interdisciplinary,
  &--dbok,
  &--theme,
  &--login {
    font-size: 0.9375rem !important;
    font-weight: 500 !important;
    color: $button__text !important;
    background: $button__bg;
    border-radius: 0.625rem;
    padding: 1rem 3.875rem;
  }

  &--theme {
    padding: 0.525rem 2.75rem;
    margin-left: auto;
  }

  &--login {
    display: block;
    font-weight: 700;
    width: rem(250px);
    margin: 1rem auto 0;

    @include bp(large) {
      margin-top: 2.5rem;
      margin-left: 0;
    }
  }

  &--dbok {
    padding: 0;
    margin: 1rem 1rem 0 1rem;
    min-width: rem(90px);
    text-align: center;
    line-height: 2.1875rem;

    @include bp(440px) {
      margin: 0 1rem;
    }

    &:last-child {
      margin-bottom: 1rem;
      @include bp(440px) {
        margin: 0 1rem;
      }
    }
  }

  &--primary .c-card-exercises-levels__list & {
    min-width: 5rem;
  }
}
</style>
