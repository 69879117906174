
import { defineComponent, computed, onMounted } from 'vue'
import { useContents, useStructures, useLocalizations, useTenant } from '@/core'
import useModal from '@/composables/useModal'

import useTranslations from '@/composables/useTranslations'
import ModalWrapper from '@/components/ModalWrapper.vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'

const DIRECTION_RTL = ['ar', 'fa-AF', 'ku', 'ps']

export default defineComponent({
  name: 'ModalConcept',
  components: {
    TheHeading,
    TheButton,
    ModalWrapper,
  },

  setup() {
    const { getLocalizationById } = useLocalizations()
    const { fetchObject } = useContents()
    const { getStructuresChildrens, fetchStructuresChildren } = useStructures()
    const { tenant } = useTenant()
    const { isModalOpen, modalContent, closeModal } = useModal()
    const { gqlContentQuery } = useTranslations()
    const conceptLocale = computed(
      () => modalContent.value.options?.translation,
    )
    const contentItems = computed(() =>
      getStructuresChildrens(modalContent.value.content.id, 'OBJECT'),
    )
    const translatedItems = computed(() =>
      getStructuresChildrens(
        modalContent.value.content.id,
        'OBJECT',
        conceptLocale.value,
      ).filter((child) =>
        getLocalizationById(child.contentId, conceptLocale.value, 'content'),
      ),
    )
    const direction = computed(() => {
      if (
        DIRECTION_RTL.some(
          (drtl: string): boolean => drtl === conceptLocale.value,
        )
      ) {
        return 'rtl'
      }

      return 'ltr'
    })

    onMounted(async () => {
      await fetchStructuresChildren(
        modalContent.value.content.id,
        {
          limit: 100,
          filter: `{"type": "OBJECT"}`,
        },
        {
          namespace: tenant.value.concepts.namespace,
        },
      )
      contentItems.value.forEach((ci) => {
        if (ci.contentId) {
          if (!conceptLocale.value) {
            fetchObject(ci.contentId, {
              namespace: tenant.value.concepts.namespace,
            })
          } else {
            gqlContentQuery(ci.contentId, conceptLocale.value, {
              namespace: tenant.value.concepts.namespace,
            }).catch(() => {
              // silence
            })
          }
        }
      })
    })

    return {
      direction,
      contentItems,
      translatedItems,
      conceptLocale,
      isModalOpen,
      closeModal,
      modalContent,
    }
  },
})
