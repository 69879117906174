import { fetchSH } from '../api'
import { buildQuery } from '../utils'

const endpoint = 'structures'

/* Structures CRUD */
const getStructuresAll = (namespace, query) => fetchSH(`${endpoint}/${namespace}/nodes${buildQuery(query)}`)
const getStructuresChildren = (namespace, idOrSlug, query) => fetchSH(`${endpoint}/${namespace}/children/nodes/${idOrSlug}${buildQuery(query)}`)
const getStructuresNode = (namespace, idOrSlug) => fetchSH(`${endpoint}/${namespace}/nodes/${idOrSlug}`)

export {
  getStructuresAll,
  getStructuresChildren,
  getStructuresNode
}
