import XAPI from '@xapi/xapi'
// xAPI.js docs: https://www.xapijs.dev/

const endpoint = `${process.env.VUE_APP_COSS_URL}/lrs/xapi`
const auth = () => 'Bearer ' + localStorage.getItem('token')
const xapi = () => new XAPI({
  endpoint,
  auth: auth()
})

export default xapi
