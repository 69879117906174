import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { beforeEach } from '@/core/router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: true,
    },
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/bokhylla',
    alias: '/d-bok',
    name: 'Dbok',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "dbok" */ '@/views/Dbok.vue'),
  },
  {
    path: '/fagbegreper',
    alias: '/fagomgrep',
    name: 'ConceptsSearch',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "conceptsSearch" */ '@/views/ConceptsSearch.vue'
      ),
  },
  {
    path: '/s/:slugPath*',
    name: 'Single',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "single" */ '@/views/Single.vue'),
  },
  {
    path: '/samarbeid-artikkel/:slugPath*',
    name: 'InterdisciplinarySingle',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "single" */ '@/views/Single.vue'),
  },
  {
    path: '/samarbeid/',
    name: 'Interdisciplinary',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "interdisciplinary" */ '@/views/Interdisciplinary.vue'
      ),
  },
  {
    path: '/samarbeid/:slugPath*',
    name: 'InterdisciplinaryArchive',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "interdisciplinaryArchive" */ '@/views/InterdisciplinaryArchive.vue'
      ),
  },
  {
    path: '/oppgave/:slugPath*',
    name: 'ExerciseSingle',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "single" */ '@/views/Single.vue'),
  },
  {
    path: '/oppgaver/',
    name: 'Exercises',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "exercises" */ '@/views/Exercises.vue'),
  },
  {
    path: '/oppgaver/:slugPath*',
    name: 'ExercisesArchive',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "exercisesArchive" */ '@/views/ExercisesArchive.vue'
      ),
  },
  {
    path: '/oppgaveprogresjon',
    name: 'Dashboard',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(beforeEach)

export default router
