import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-home" }
const _hoisted_2 = { class: "v-home__description" }
const _hoisted_3 = { class: "v-home__section" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "v-home__section-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ButtonSources = _resolveComponent("ButtonSources")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TheHeading, {
        level: "h1",
        class: "v-home__heading"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('HOME_HEADING')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('HOME_DESCRIPTION')), 1),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", {
          class: "v-home__no-products",
          innerHTML: _ctx.$t('HOME_NO_PRODUCTS')
        }, null, 8, _hoisted_4), [
          [_vShow, _ctx.productsChecked && !_ctx.productsAvailable.length]
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (product) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["v-home__product", [product.class]]),
              key: product.title
            }, [
              (product.homeView && _ctx.showProduct(product))
                ? (_openBlock(), _createBlock(_component_Card, {
                    key: 0,
                    title: product.title,
                    description: product.description || '',
                    "image-id": product.homeView.cover,
                    item: product,
                    actions: _ctx.cardActions,
                    class: "v-home__section-card"
                  }, null, 8, ["title", "description", "image-id", "item", "actions"]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_ButtonSources)
  ], 64))
}