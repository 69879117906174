<template>
  <div class="u-px">
    <div ref="mAuthorWrapper" class="c-embed__frame">
      <iframe
        class="c-mauthor-frame"
        name="mAuthorFrame"
        ref="mAuthorFrame"
        :src="mauthorSrc"
        tabindex="0"
        allowfullscreen="allowfullscreen"
        allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
      ></iframe>
    </div>
    <ContentLoader v-if="!mAuthorLoaded" height="160" width="100%">
      <rect x="0" y="0" :width="contentWidth" :height="contentHeight"></rect>
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import { computed, ref, onBeforeUnmount, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import XAPI from '@xapi/xapi'
import _ from 'lodash'
import { useAuth, useBreadcrumbs } from '@/core'
import { generateStatement, actor } from '@/lrs'
import xapi from '@/core/services/lrs'
import {
  getExerciseState,
  createOrUpdateExerciseState,
} from '@/services/exerciseState'

export default {
  name: 'EmbedExercise',

  components: {
    ContentLoader,
  },

  props: {
    item: Object,
    data: Object,
  },

  setup(props) {
    let tempState = null
    const mAuthorLoaded = ref(false)
    const mAuthorFrame = ref(null)
    const mAuthorWrapper = ref(null)
    const contentWidth = ref(1026)
    const contentHeight = ref(672)
    const { token } = useAuth()
    const router = useRouter()
    const route = useRoute()
    const { breadcrumbs } = useBreadcrumbs()
    const hasCompleted = ref(false)
    const lastStatement = ref(null)
    const mauthorSrc = computed(() => {
      const tokenString = token.value || process.env.VUE_APP_COSS_TOKEN
      return `${process.env.VUE_APP_MAUTHOR_URL}?data=${props.data.content.embedId}&gateway=${process.env.VUE_APP_COSS_URL}&token=${tokenString}`
    })
    const initStatement = async () => {
      const statementsRes = await xapi().getStatements({
        agent: actor(),
        activity: `${
          process.env.VUE_APP_COSS_URL
        }/structures/${route.params.slugPath.join('/')}`,
        limit: 1,
      })
      lastStatement.value = statementsRes.data.statements?.[0]
      if (
        lastStatement.value &&
        JSON.parse(JSON.stringify(lastStatement.value.verb)) ===
          XAPI.Verbs.COMPLETED
      ) {
        hasCompleted.value = true
      }

      return statementsRes
    }
    const handleStatement = async (eventData) => {
      const parsedData = JSON.parse(eventData)
      const exerciseResult = {
        completion:
          parsedData.attemptScore?.scaled === 1 && parsedData.allPagesVisited,
        success: parsedData.attemptScore?.scaled === 1,
        score: {
          scaled: parsedData.attemptScore.scaled,
        },
      }
      const exerciseObject = {
        objectType: 'Activity',
        id: `${
          process.env.VUE_APP_COSS_URL
        }/structures/${route.params.slugPath.join('/')}`,
        definition: {
          type: 'http://adlnet.gov/expapi/activities/assessment',
          name: { 'en-US': 'Grip Exercise' },
          extensions: {
            [`${process.env.VUE_APP_COSS_URL}/mauthor-lesson/lessons`]:
              props.data.content.embedId,
          },
        },
      }
      const newStatement = generateStatement(exerciseResult, exerciseObject)
      // Set statment if first attempt
      if (!lastStatement.value) {
        await xapi().sendStatement({
          statement: newStatement,
        })
        lastStatement.value = newStatement

        return
      }
      // send statement only if has changes
      if (
        !_.isEqual(newStatement, {
          actor: JSON.parse(JSON.stringify(lastStatement.value.actor)),
          verb: JSON.parse(JSON.stringify(lastStatement.value.verb)),
          result: JSON.parse(JSON.stringify(lastStatement.value.result)),
          object: JSON.parse(JSON.stringify(lastStatement.value.object)),
        })
      ) {
        await xapi().sendStatement({
          statement: newStatement,
        })
        lastStatement.value = newStatement
      }
    }

    onMounted(() => {
      initStatement()
      window.addEventListener('message', handleMessage)
      window.addEventListener('resize', screenResiseHandler)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('message', handleMessage)
      window.removeEventListener('resize', screenResiseHandler)
      saveStateToDB()
    })

    function handleMessage(event) {
      const { eventType, eventData } = destructureMessage(event)

      switch (eventType) {
        case 'ATTEMPT_COMPLETE':
          if (hasCompleted.value) break
          handleStatement(eventData)
          saveTempState(eventData)
          break
        case 'RESIZE':
          resizeFrame(eventData)
          break
        case 'PLAYER_READY':
          restoreLessonState()
          break
        case 'PLAYER_CLOSE':
          if (breadcrumbs.value[breadcrumbs.value.length - 2]) {
            router.push(breadcrumbs.value[breadcrumbs.value.length - 2].to)
          }
          break
        default:
          break
      }
    }

    async function restoreLessonState() {
      const exSyncData = await getExerciseState(props.data.content.embedId)
      tempState = exSyncData?.state ? exSyncData.state : null
      sendMessageToFrame(`LESSON_STATE:${JSON.stringify(tempState)}`)
    }

    function saveTempState(stateString) {
      const state = JSON.parse(stateString)
      tempState = state
      saveStateToDB()
    }

    async function saveStateToDB() {
      if (tempState) {
        await createOrUpdateExerciseState(props.data.content.embedId, tempState)
      }
    }

    function resizeFrame() {
      mAuthorLoaded.value = true
      mAuthorFrame.value.style.width = `${contentWidth.value}px`
      mAuthorFrame.value.style.height = `${contentHeight.value}px`
      setTimeout(screenResiseHandler, 1)
    }

    function screenResiseHandler() {
      if (mAuthorWrapper.value) {
        const scale = Math.min(
          1,
          mAuthorWrapper.value.offsetWidth / contentWidth.value,
        )
        mAuthorFrame.value.style.transform = `scale(${scale})`
      }
    }

    function destructureMessage(event) {
      const eventTypeMarker =
        event.data?.indexOf(':') > -1
          ? event.data?.indexOf(':')
          : event.data?.length

      const eventType = event.data.substr(0, eventTypeMarker)
      const eventData = event.data.substr(eventTypeMarker + 1)

      return {
        eventType,
        eventData,
      }
    }

    function sendMessageToFrame(message) {
      window.frames['mAuthorFrame'].postMessage(
        message,
        `${process.env.VUE_APP_MAUTHOR_URL}`,
      )
    }

    return {
      mauthorSrc,
      mAuthorFrame,
      mAuthorWrapper,
      mAuthorLoaded,
      contentWidth,
      contentHeight,
    }
  },
}
</script>
<style lang="scss" scoped>
.c-embed__container,
.c-embed__frame {
  position: relative;
  text-align: center;

  .c-mauthor-frame {
    border: none;
    width: 0;
    height: 0;
    overflow-y: hidden;
    transform: scale(1);
    transform-origin: left top;
  }
}
</style>
