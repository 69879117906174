
import { computed, onBeforeMount, watch, defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useAuth, useTenant } from '@/core'
import { initSync as initSyncExercises } from '@/services/exerciseState'
import {
  initSync as initSyncSettings,
  getSetting,
  settings,
} from '@/services/userSettings'
import { useI18n } from 'vue-i18n'
import useAccessCheck from '@/composables/useAccessCheck'
import Sidebar from '@/components/Sidebar.vue'
import Topbar from '@/components/Topbar.vue'
import Modal from '@/components/Modal.vue'
import useSWUpdate from '@/composables/useSWUpdate'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    Sidebar,
    Topbar,
    Modal,
  },

  props: {
    tenantConfig: Object,
  },

  setup(props) {
    const { updateExists, refreshApp, initSWUpdateWatch } = useSWUpdate()
    const route = useRoute()
    const { user } = useAuth()
    const { productsCheck } = useAccessCheck()
    const routeName = computed(() => route.name?.toString().toLowerCase())
    const hasSidebar = computed(() => route.name !== 'Login')
    const { initTenant } = useTenant()
    const { locale } = useI18n()
    const { closeModal, setModal } = useModal()
    const { t } = useI18n()

    onBeforeMount(() => {
      initTenant(props.tenantConfig)
      initSWUpdateWatch()
    })

    onMounted(() => {
      document.body.addEventListener('keydown', function () {
        document.body.classList.add('using-keyboard')
      })
      document.body.addEventListener('mousedown', function () {
        document.body.classList.remove('using-keyboard')
      })
    })

    watch(
      () => updateExists.value,
      (val) => {
        if (val) {
          setModal('ModalAction', {
            title: t(`MODAL_SW_TITLE`),
            content: t(`MODAL_SW_DESC`),
            type: 'primary',
            actions: {
              confirm: {
                title: t(`MODAL_SW_BUTTON`),
                on() {
                  refreshApp()
                  closeModal()
                },
              },
            },
          })
        }
      },
    )

    watch(
      () => user.value,
      async () => {
        productsCheck()
        initSyncExercises()
        await initSyncSettings()
        const savedLang = (await getSetting(settings.lang))?.value
        if (savedLang) {
          locale.value = savedLang
        }
      },
    )

    return {
      hasSidebar,
      routeName,
    }
  },
})
