<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle class="c-icon-radio__circle--outer" cx="10" cy="10" r="8" />
    <circle class="c-icon-radio__circle--inner" cx="10" cy="10" r="4" />
  </svg>
</template>

<style lang="scss">
.c-icon-radio__circle--outer {
  stroke-width: 2;
  stroke: $radio-button;
  fill: none;
}

.c-icon-radio__circle--inner {
  stroke-width: 0;
  fill: rgba($radio-button, 0);
  transition: fill 0.2s;

  input[type='radio']:checked + * svg & {
    fill: $radio-button;
  }
}
</style>
