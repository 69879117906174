import { computed } from 'vue'

import DB from '@/core/services/db'
import useAuth from '@/core/useAuth'
import useTenant from '@/core/useTenant'

const db = new DB('core')
const { user } = useAuth()
const { tenant } = useTenant()
const prefix = computed(
  () =>
    `${tenant.value.namespace}-${tenant.value.slug}-${user.value.providerId}`,
)

const createOrUpdateExerciseState = async (id, state, doSync = true) => {
  try {
    const item = await db.createOrUpdate({
      _id: `${prefix.value}-${id}`,
      state,
    })
    if (doSync) {
      db.syncData()
    }
    return item
  } catch (err) {
    console.error(err)
  }
}

const getExerciseState = async (id) => {
  try {
    const state = await db.getData(`${prefix.value}-${id}`)

    return state
  } catch (err) {
    return null
  }
}

const initSync = async () => {
  await db.initSync()
}

export { getExerciseState, createOrUpdateExerciseState, initSync }
