import { ref } from 'vue'
import { getObjectsAll, getObject } from './services/contents'
import { apiResponseItemsFilter } from './utils'
import useTenant from './useTenant'
import useLocalizations from './useLocalizations'

const { tenant } = useTenant()
const { getLocalizationById } = useLocalizations()

const objects = ref([]) 
const fetchObjects = async (query, options = {}) => {
  const namespace = options.namespace || tenant.value.namespace
  const res = await getObjectsAll(namespace, query)

  setObject(res.data)
}
const fetchObject = async (contentId, options = {}) => {
  const namespace = options.namespace || tenant.value.namespace
  const res = await getObject(namespace, contentId)

  setObject([res])
}
const getObjectById = (contentId, locale = '') => {
  let obj = objects.value.filter(obj => obj.id === contentId)[0]

  if (obj && locale) {
    obj = JSON.parse(JSON.stringify(obj))
    obj.content = Object.assign({}, obj.content, getLocalizationById(contentId, locale, 'content')?.content)
  }

  return obj
}
const setObject = object => {
  objects.value = apiResponseItemsFilter(object, objects.value)
}

export default () => {
  return {
    objects,
    fetchObject,
    fetchObjects,
    getObjectById,
    setObject
  }
}
