import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheButton = _resolveComponent("TheButton")!
  const _component_ModalWrapper = _resolveComponent("ModalWrapper")!

  return (_ctx.modalContent)
    ? (_openBlock(), _createBlock(_component_ModalWrapper, {
        key: 0,
        title: _ctx.modalContent.title
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", {
            class: "c-modal-action__content",
            innerHTML: _ctx.modalContent.content
          }, null, 8, _hoisted_1)
        ]),
        footer: _withCtx(() => [
          (_ctx.modalContent.actions.cancel)
            ? (_openBlock(), _createBlock(_component_TheButton, {
                key: 0,
                class: "c-modal-action__btn c-modal-action__btn--cancel",
                type: _ctx.modalContent.actions.cancel.type || 'primary-alt',
                onClick: _ctx.modalContent.actions.cancel.on
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.modalContent.actions.cancel.title), 1)
                ]),
                _: 1
              }, 8, ["type", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.modalContent.actions.confirm)
            ? (_openBlock(), _createBlock(_component_TheButton, {
                key: 1,
                class: "c-modal-action__btn c-modal-action__btn--confirm",
                type: _ctx.modalContent.actions.confirm.type || 'primary',
                onClick: _ctx.modalContent.actions.confirm.on
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.modalContent.actions.confirm.title), 1)
                ]),
                _: 1
              }, 8, ["type", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}