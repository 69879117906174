import { computed } from 'vue'
import DB from '@/core/services/db'
import { useAuth, useTenant } from '@/core'

const { user } = useAuth()
const { tenant } = useTenant()
const db = new DB()
const prefix = computed(() => `${tenant.value.namespace}-${user.value._id}`)

const settings = {
  lang: 'lang',
}

const createOrUpdateSetting = async (settingId, value) => {
  try {
    const item = await db.createOrUpdate({
      _id: `${prefix.value}-settings-${settingId}`,
      value,
    })
    await db.syncData()
    return item
  } catch (err) {
    console.error(err)
  }
}

const getSetting = async (settingId) => {
  try {
    const value = await db.getData(`${prefix.value}-settings-${settingId}`)

    return value
  } catch (err) {
    return null
  }
}

const initSync = async () => {
  await db.initSync()
}

export { settings, getSetting, createOrUpdateSetting, initSync }
