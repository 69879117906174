import { ref } from 'vue'
import { geti18nContents, geti18nStructures } from './services/localizations'

const EDTECH_SERVICES = {
  structure: 'edtech.structure',
  content: 'edtech.content'
}
const localizations = ref([])
const fetchContentsLocalization = async (objectId, locale) => {
  const res = await geti18nContents(objectId, locale)

  setLocalization(res)
}
const fetchStructuresLocalization = async (objectId, locale) => {
  const res = await geti18nStructures(objectId, locale)

  setLocalization(res)
}
const getLocalizationById = (objectId, locale, service) => localizations.value.filter(l => l.objectId === objectId && l.locale === locale && l.service === EDTECH_SERVICES[service])[0]
const setLocalization = (newLocalization) => {
  if (localizations.value.some(l => newLocalization.objectId === l.objectId && newLocalization.locale === l.locale)) return

  localizations.value.push(newLocalization)
}

export default () => {
  return {
    localizations,
    fetchContentsLocalization,
    fetchStructuresLocalization,
    getLocalizationById,
    setLocalization
  }
}
