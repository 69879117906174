<template>
  <div v-if="toc.length" class="c-content-toc">
    <div class="c-content-toc__wrapper" ref="tocWrapper">
      <span class="c-content-toc__title">{{ $t('TABLE_OF_CONTENT') }}</span>
      <nav class="c-content-toc__nav">
        <ul class="c-content-toc__list">
          <li
            class="c-content-toc__item"
            v-for="(item, itemId) in toc"
            :key="item.title"
          >
            <a
              href="#"
              rel="nofollow"
              class="c-content-toc__link"
              :data-toc-id="`${item.id}`"
              @click.prevent="onAnchorScroll(item.id, itemId)"
            >
              <span class="c-content-toc__id">{{ itemId + 1 }}.</span>
              {{ item.title }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ContentToC',

  props: {
    parentClass: String,
  },

  setup(props) {
    const { locale } = useI18n()
    const toc = ref([])
    const lastRaf = 0
    const tocBuilder = async (raf) => {
      if (!lastRaf || raf - lastRaf >= 2 * 1000) {
        const headings = Array.from(
          document.querySelectorAll(`.${props.parentClass}__wrapper h2`),
        )
        const items = []

        headings.forEach((h) => {
          items.push({
            title: h.innerText,
            id: h.id,
          })
        })
        if (toc.value.length != items.length) {
          toc.value = items
        }
      }
      requestAnimationFrame(tocBuilder)
    }

    const onAnchorScroll = (element) => {
      document
        .querySelector(`#${element}`)
        .scrollIntoView({ behavior: 'smooth' })
    }

    watch(locale, () => {
      toc.value = []
      requestAnimationFrame(tocBuilder)
    })

    onMounted(() => {
      requestAnimationFrame(tocBuilder)
    })

    return {
      toc,
      onAnchorScroll,
    }
  },
})
</script>

<style lang="scss">
.c-content-toc__title {
  font-weight: bold;
}

.c-content-toc__list {
  list-style: none;
  padding: 0;
}

.c-content-toc__link {
  display: inline-block;
  margin-bottom: 0.25rem;
  color: $color-text;
  text-decoration: none;
  border-bottom: 0.0625rem solid $color-text;
  transition: all 0.2s;

  &:hover {
    color: $color-text--hover;
    border-color: $color-text--hover;
  }
}

.c-content-toc__id {
  display: inline-block;
  min-width: 1rem;
}
</style>
