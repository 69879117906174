import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-language-select u-px" }
const _hoisted_2 = {
  key: 0,
  class: "c-language-select__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label) + ":", 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Multiselect, {
      modelValue: _ctx.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      options: _ctx.languageList,
      "can-clear": _ctx.canClear,
      "can-deselect": _ctx.canDeselect,
      placeholder: _ctx.placeholder,
      onChange: _ctx.onSelectChange
    }, null, 8, ["modelValue", "options", "can-clear", "can-deselect", "placeholder", "onChange"])
  ]))
}