import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-modal-dashboard-student__top-heading" }
const _hoisted_2 = { class: "c-modal-dashboard-student__heading-groups" }
const _hoisted_3 = { class: "c-modal-dashboard-student__subject-list" }
const _hoisted_4 = {
  key: 0,
  src: "/img/fading-circles.gif"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  src: "/img/fading-circles.gif"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  src: "/img/fading-circles.gif"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheButton = _resolveComponent("TheButton")!
  const _component_FilterThemes = _resolveComponent("FilterThemes")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_ModalWrapper = _resolveComponent("ModalWrapper")!

  return (_ctx.modalContent)
    ? (_openBlock(), _createBlock(_component_ModalWrapper, {
        key: 0,
        class: "c-modal-dashboard-student"
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.modalContent.content.student.firstName) + " " + _toDisplayString(_ctx.modalContent.content.student.lastName), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.modalContent.content.student.group), 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.modalContent.content.student.groupNames), 1)
          ]),
          _createVNode(_component_TheButton, {
            class: "c-modal-dashboard-student__top-close",
            icon: "IconClose",
            onClick: _ctx.closeModal
          }, null, 8, ["onClick"]),
          _createElementVNode("section", null, [
            _createVNode(_component_FilterThemes, {
              availableExercises: _ctx.availableExercises,
              onFilterChange: _ctx.onFilterChange
            }, null, 8, ["availableExercises", "onFilterChange"]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredThemes, (subject) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "c-modal-dashboard-student__subject",
                  key: subject.id
                }, [
                  _createVNode(_component_DataGrid, {
                    rows: subject.rows,
                    columns: _ctx.mapColumns(subject.name)
                  }, {
                    "level-1": _withCtx((slotProps) => [
                      (!slotProps.value['level-1'])
                        ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(slotProps.value['level-1']), 1))
                    ]),
                    "level-2": _withCtx((slotProps) => [
                      (!slotProps.value['level-2'])
                        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(slotProps.value['level-2']), 1))
                    ]),
                    "level-3": _withCtx((slotProps) => [
                      (!slotProps.value['level-3'])
                        ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(slotProps.value['level-3']), 1))
                    ]),
                    _: 2
                  }, 1032, ["rows", "columns"])
                ]))
              }), 128))
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}