<template>
  <div class="c-filter-students">
    <div class="c-filter-students-header">
      {{ $t('VIEW_DASHBOARD_FILTERS') }}
    </div>
    <div class="c-filter-students-select">
      <span class="c-filter-students-select__label">{{
        $t('FILTERS_STUDENT_GROUP_OR_CLASS')
      }}</span>
      <Multiselect
        v-model="select.value"
        :placeholder="$t('FILTERS_ALL')"
        track-by="name"
        v-bind="select"
        mode="tags"
        :searchable="true"
        :createTag="true"
        ref="multiselect"
        @change="selectValue"
      >
        <template v-slot:option="{ option }">
          {{ option.label }}
        </template>
      </Multiselect>
    </div>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import { useI18n } from 'vue-i18n'

export default {
  name: 'FilterStudents',

  components: {
    Multiselect,
  },

  setup(props, { emit }) {
    const { t } = useI18n()

    let select = {
      mode: 'multiple',
      groups: true,
      closeOnSelect: false,
      options: [
        {
          label: t('DASHBOARD_GRID_CLASSES'),
          options: [
            { value: '8a', label: '8A' },
            { value: '8d', label: '8D' },
            { value: '9b', label: '9B' },
          ],
        },
        {
          label: t('DASHBOARD_GRID_GROUPS'),
          options: [
            { value: 'grip8', label: '8 GRIP Science' },
            { value: 'grip9', label: '9 GRIP Science' },
          ],
        },
      ],
    }

    function selectValue() {
      emit('change-value', select)
    }

    return { select, selectValue }
  },
}
</script>

<style lang="scss">
.c-filter-students {
  padding-top: 1rem;
  background-color: $color-2;
}

.c-filter-students-header {
  font-weight: 500;
  font-size: 1rem;
}

.c-filter-students-select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;

  &__label {
    font-weight: 500;
    font-size: 1rem;
    margin-right: 2.5rem;
  }

  .multiselect {
    border: 0;
    box-shadow: none;
    background-color: $language-select__bg;
    width: 12rem;
    margin: 0.625rem 0;
  }

  .multiselect-single-label {
    font-weight: 700;
  }

  .multiselect-caret {
    margin-right: 0.25rem;
    background-color: $language-select__chevron;
  }

  .multiselect-tag {
    background-color: $color-3;
    &:hover {
      background-color: $color-3;
    }
  }

  .multiselect-group-label.is-selected {
    background-color: $color-3;
  }

  .multiselect-placeholder {
    font-weight: 700;
    color: $language-select__placeholder;
  }

  .multiselect-dropdown {
    left: 0.25rem;
    border-radius: 0;
    border: 0;
  }

  .multiselect-no-options {
    display: none;
  }

  .multiselect-option {
    color: $language-select__text;
    background-color: $language-select__bg;

    &.is-selected {
      color: $language-select__text;
      font-weight: bold;
    }
    &.is-pointed {
      background-color: $language-select--hover__bg;
    }
  }

  .c-nav-mobile & {
    padding-left: 0;
    margin: auto;
    height: 4rem;

    .multiselect-option {
      background-color: $language-select--mobile__bg;
    }
  }
}
</style>
