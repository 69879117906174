
import { ref, onBeforeMount, nextTick, defineComponent } from 'vue'
import mediumZoom from 'medium-zoom'

import { useAssets } from '@/core'
import { utils } from '@/core'

export default defineComponent({
  name: 'Image',

  props: {
    data: Object,
  },

  setup(props) {
    const { fetchAsset, getAssetById } = useAssets()
    const image = ref(null)
    const imageSize = ref(null)
    const imgTag = ref(null)

    const getImageOption = (key) => {
      let imageOption = null

      if (props.data.content.options) {
        imageOption = props.data.content.options.find(
          (option) => option.key == key,
        )
        return imageOption ? imageOption.value : null
      }

      return imageOption
    }

    onBeforeMount(async () => {
      await fetchAsset(props.data.content.assetId, {
        namespace: props.data.namespace,
      })
      image.value = getAssetById(props.data.content.assetId)
      image.value.url = utils.addCloudinaryUrlParams(
        image.value.url,
        'w_1200,c_limit',
      )
      image.value.altText = props.data.content.altText || ''
      imageSize.value = getImageOption('size')

      nextTick(() => {
        mediumZoom(imgTag.value)
      })
    })

    return {
      image,
      imageSize,
      imgTag,
    }
  },
})
