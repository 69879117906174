
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import TheHeading from '@/components/TheHeading.vue'
import ResourcesConcept from '@/components/RelatedResourceCollection/Concept.vue'
import ResourcesFiles from '@/components/RelatedResourceCollection/Files.vue'
import ResourcesLink from '@/components/RelatedResourceCollection/Link.vue'

export default defineComponent({
  name: 'RelatedResourcesCollection',

  components: {
    TheHeading,
    ResourcesConcept,
    ResourcesFiles,
    ResourcesLink,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { t } = useI18n()
    const resources = computed(() => props.data.content.resources)
    const allowedTypes = ['concept', 'files', 'link']
    const groupedResources = computed(() => {
      const groups = []

      allowedTypes.forEach((at) => {
        const obj: {
          title?: string
          type?: string
          items?: []
        } = {}

        obj.title = t(`RRC_${at.toUpperCase()}`)
        obj.type = at
        obj.items = resources.value.filter(
          (resource) => resource.type === obj.type,
        )
        if (obj.items.length) {
          groups.push(obj)
        }
      })

      return groups
    })
    const getComponentName = (type) => {
      const compNames = {
        concept: 'ResourcesConcept',
        files: 'ResourcesFiles',
        link: 'ResourcesLink',
      }

      return compNames[type]
    }

    return {
      groupedResources,
      getComponentName,
    }
  },
})
