import { utils } from '@/core'
import { Config } from 'types/interface/Config'

const customDataByEnv = {
  namespace: {
    production: 'e13c947e-0352-4cce-ba43-e36631e38b4b',
    development: 'c9b58625-13a4-4b6d-9182-21db6c4b28ac',
  },
  conceptsId: {
    production: '179616e3-959b-4a9c-9d02-992a5608b6ef',
    development: '757c226f-6006-4cb6-bdce-611166128db2',
  },
  interdisciplinaryId: {
    production: 'cd767292-4e93-4b63-ac42-d24410a6abae',
    development: 'e86edc77-d248-427f-adfe-5a0a4a7fd6d3',
  },
  matematikkId: {
    production: 'ab60c34a-3174-4b0e-97fb-3bc08bb95060',
    development: 'aba6d64e-457b-493e-bbf5-34fd1f20b9bd',
  },
  naturfagId: {
    production: '5727d6d4-5518-48d3-8c57-25f151eda974',
    development: 'a5ce93d4-72cc-45b4-b3d4-efedcefb9baa',
  },
  samfunnsfagId: {
    production: '19e5fbb7-43e6-4aa6-8be5-d20ded411895',
    development: 'e4fe67c0-c81f-4b6a-8d78-ec91174f8798',
  },
  matematikkContentId: {
    production: 'a98f1542-5165-46be-bb75-f098eebcf3ac',
    development: '34e68b4c-8f5b-4aba-b8dc-f19b5c467034',
  },
  naturfagContentId: {
    production: '13ecb61c-6951-4e97-a86a-6e944231b569',
    development: 'ac6b2f31-bb1d-43bc-b8b6-9cfed9cf32a7',
  },
  samfunnsfagContentId: {
    production: '9ff19ea0-af5f-4dcd-82e8-2b066ddb4ef4',
    development: '6d309575-412d-4889-a338-9547dcd81443',
  },
}

const config: Config = {
  name: 'Grip ung',
  slug: 'grip',
  namespace: utils.getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: '',
  },
  assets: {},
  styles: [],
  images: {
    login: {
      image: '/img/login-bg.jpg',
      caption: '&copy; Mr.kitsadakron Pongha / EyeEm / Getty Images',
    },
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  concepts: {
    namespace: utils.getDataByEnv('namespace', customDataByEnv),
    slug: 'grip/concepts',
    langs: [
      {
        code: 'ar',
        label: {
          nb: 'Arabisk',
          nn: 'Arabisk',
        },
      },
      {
        code: 'fa-AF',
        label: {
          nb: 'Dari',
          nn: 'Dari',
        },
      },
      {
        code: 'ku',
        label: {
          nb: 'Kurdisk',
          nn: 'Kurdisk',
        },
      },
      {
        code: 'ps',
        label: {
          nb: 'Pashto',
          nn: 'Pashto',
        },
      },
      {
        code: 'so',
        label: {
          nb: 'Somalisk',
          nn: 'Somalisk',
        },
      },
      {
        code: 'ti',
        label: {
          nb: 'Tigrinya',
          nn: 'Tigrinya',
        },
      },
    ],
  },
  filters: {
    levels: ['1', '2', '3'],
  },
  dbok: [
    {
      name: 'Grip 1',
      items: [
        {
          title: 'Grip 1 Samfunnsfag og naturfag',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '51ecee18c5b360e43d003776',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '51ecf2d0c5b360e43d00481d',
            },
          ],
        },
        {
          title: 'Grip 1 Matematikk',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '52e8d3a830e10d507a0044cc',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '5421462c1480b93b07009638',
            },
          ],
        },
      ],
    },
    {
      name: 'Grip 2',
      items: [
        {
          title: 'Grip 2 Samfunnsfag og naturfag',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '53be8ab6d9c9607963002c8d',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '54095ed4ad8375700a004c4e',
            },
          ],
        },
        {
          title: 'Grip 2 Matematikk',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '550a7f969166eed7700000a5',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '550fd23f6d3f79171c000004',
            },
          ],
        },
      ],
    },
    {
      name: 'Grip 3',
      items: [
        {
          title: 'Grip 3 Geografi',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '59770bf63ec8093619007b25',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '5977124d3ec8093619008590',
            },
          ],
        },
        {
          title: 'Grip 3 Naturfag',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '5976fd6d3ec80936190059ec',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '597706753ec8093619006a59',
            },
          ],
        },
        {
          title: 'Grip 3 Matematikk',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '5d44067d4cf27a0000003aab',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '5da5b867c978a6001279cfa6',
            },
          ],
        },
        {
          title: 'Grip 3 Historie',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '5a8d4472ce67a8000000108c',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '5a8d4900ce67a800000022eb',
            },
          ],
        },
        {
          title: 'Grip 3 Samfunnskunnskap',
          description: 'Elevbok',
          books: [
            {
              text: 'DBOK_LANG_BM',
              id: '5a8d3925ce67a80000000033',
            },
            {
              text: 'DBOK_LANG_NN',
              id: '5a8d3de3ce67a80000000876',
            },
          ],
        },
      ],
    },
  ],
  menu: {
    nb: [
      {
        title: 'Hjem',
        to: '/',
        icon: 'IconDashboard',
        class: 'link-home',
      },
      {
        title: 'Lærerside',
        to: '/s/grip/laererside',
        icon: 'IconLaererside',
        class: 'link-om-grip',
        roleAccess: ['teacher'],
        homeView: {
          cover: '40127b7a-d647-4279-9163-bda84dd630e3',
        },
      },
      {
        title: 'Bokhylla',
        to: '/bokhylla',
        icon: 'IconBokhylla',
        class: 'link-d-bok',
        homeView: {
          cover: '06dcab49-7553-46dd-9f66-106597e7f223',
        },
      },
      {
        title: 'Fagbegreper',
        to: '/fagbegreper',
        icon: 'IconFagbegreper',
        class: 'link-fagbegreper',
        productAccess: ['concepts'],
        homeView: {
          cover: '52afded2-aed5-4230-b4ea-15eb0e8ff500',
        },
      },
      {
        title: 'Samarbeid',
        to: '/samarbeid',
        icon: 'IconSamarbeid',
        class: 'link-interdisciplinary',
        productAccess: ['interdisciplinary'],
        homeView: {
          cover: 'f3f29545-0878-4c71-8ec5-bc0a437a211e',
        },
      },
      {
        title: 'Oppgaver',
        to: '/oppgaver',
        icon: 'IconOppgaver',
        class: 'link-exercises',
        productAccess: ['matematikk', 'samfunnsfag', 'naturfag'],
        homeView: {
          cover: '964f2e43-31c8-4c14-84fe-931cc8d54515',
        },
      },
      {
        title: 'Oppgaveprogresjon',
        to: '/oppgaveprogresjon',
        icon: 'IconOppgaveprogresjon',
        class: 'link-dashboard',
        productAccess: ['matematikk', 'samfunnsfag', 'naturfag'],
        roleAccess: ['teacher'],
        homeView: {
          cover: 'be5b444a-71c6-4078-ada6-8eb4db50a2f0',
        },
      },
    ],
    nn: [
      {
        title: 'Heim',
        to: '/',
        icon: 'IconDashboard',
        class: 'link-home',
      },
      {
        title: 'Lærarside',
        to: '/s/grip/laererside',
        icon: 'IconLaererside',
        class: 'link-om-grip',
        roleAccess: ['teacher'],
        homeView: {
          cover: '40127b7a-d647-4279-9163-bda84dd630e3',
        },
      },
      {
        title: 'Bokhylla',
        to: '/bokhylla',
        icon: 'IconBokhylla',
        class: 'link-d-bok',
        homeView: {
          cover: '06dcab49-7553-46dd-9f66-106597e7f223',
        },
      },
      {
        title: 'Fagomgrep',
        to: '/fagomgrep',
        icon: 'IconFagbegreper',
        class: 'link-fagbegreper',
        productAccess: ['concepts'],
        homeView: {
          cover: '52afded2-aed5-4230-b4ea-15eb0e8ff500',
        },
      },
      {
        title: 'Samarbeid',
        to: '/samarbeid',
        icon: 'IconSamarbeid',
        class: 'link-interdisciplinary',
        productAccess: ['interdisciplinary'],
        homeView: {
          cover: 'f3f29545-0878-4c71-8ec5-bc0a437a211e',
        },
      },
      {
        title: 'Oppgaver',
        to: '/oppgaver',
        icon: 'IconOppgaver',
        class: 'link-exercises',
        productAccess: ['matematikk', 'samfunnsfag', 'naturfag'],
        homeView: {
          cover: '964f2e43-31c8-4c14-84fe-931cc8d54515',
        },
      },
      {
        title: 'Oppgaveprogresjon',
        to: '/oppgaveprogresjon',
        icon: 'IconOppgaveprogresjon',
        class: 'link-dashboard',
        productAccess: ['matematikk', 'samfunnsfag', 'naturfag'],
        roleAccess: ['teacher'],
        homeView: {
          cover: 'be5b444a-71c6-4078-ada6-8eb4db50a2f0',
        },
      },
    ],
  },
  eportalProducts: [
    {
      id: utils.getDataByEnv('conceptsId', customDataByEnv),
      product: 'concepts',
      type: 'concepts',
      langConst: 'PRODUCT_CONCEPTS',
    },
    {
      id: utils.getDataByEnv('interdisciplinaryId', customDataByEnv),
      product: 'interdisciplinary',
      type: 'interdisciplinary',
      langConst: 'PRODUCT_INTERDISCIPLINARY',
    },
    {
      id: utils.getDataByEnv('matematikkId', customDataByEnv),
      contentId: utils.getDataByEnv('matematikkContentId', customDataByEnv),
      product: 'matematikk',
      type: 'exercises',
      langConst: 'PRODUCT_MATH',
    },
    {
      id: utils.getDataByEnv('naturfagId', customDataByEnv),
      contentId: utils.getDataByEnv('naturfagContentId', customDataByEnv),
      product: 'naturfag',
      type: 'exercises',
      langConst: 'PRODUCT_SCIENCE',
    },
    {
      id: utils.getDataByEnv('samfunnsfagId', customDataByEnv),
      contentId: utils.getDataByEnv('samfunnsfagContentId', customDataByEnv),
      product: 'samfunnsfag',
      type: 'exercises',
      langConst: 'PRODUCT_SOCIAL_STUDIES',
    },
  ],
}

export default config
