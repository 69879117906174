
import { ref, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useStructures } from '@/core'
import TheButton from '@/components/TheButton.vue'

export default defineComponent({
  name: 'CardExercisesLevels',

  components: {
    TheButton,
  },

  props: {
    parent: Object,
  },

  setup(props) {
    const route = useRoute()
    const { fetchStructuresChildren, getStructuresChildrens } = useStructures()

    const levels = ref([])
    const loading = ref(false)
    const loadLevels = async () => {
      if (loading.value === true) {
        return
      }
      loading.value = true

      await fetchStructuresChildren(props.parent.id)
      levels.value = getStructuresChildrens(props.parent.id, 'DIRECTORY')
      levels.value.map((item) => {
        item.to = `/oppgave/${(route.params.slugPath as string[]).join('/')}/${
          props.parent.slug
        }/${item.slug}`

        return item
      })

      loading.value = false
    }
    return {
      levels,
      loadLevels,
      loading,
    }
  },
})
