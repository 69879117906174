import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from '@/i18n'
import { mdHtml } from '@/directives'
import tenantConfig from '@/config/tenants/grip'
import ContentRenderer from '@/components/ContentRenderer.vue'
import 'katex/dist/katex.css'
import 'normalize.css'

const head = createHead()

createApp(App, {
  tenantConfig,
})
  .use(head)
  .use(i18n)
  .use(router)
  .directive('md-html', mdHtml)
  .component('ContentRenderer', ContentRenderer)
  .mount('#app')
