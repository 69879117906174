
import { ref, computed, onMounted, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

const ARIA_SORT_ORDER = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  NONE: 'none',
}

export default defineComponent({
  props: {
    loading: Boolean,
    columns: Array,
    rows: Array,
    initialSortKey: String,
    ariaLabelledby: String,
    ariaLabel: String,
    focusableHeadCells: Boolean,
    focusableRowCells: Boolean,
  },
  components: {
    Icon,
  },
  emits: ['update:sort-key', 'update:sort-order', 'row-selected'],
  setup(props, { slots, emit }) {
    const dataGrid = ref(null)
    const gridCurrentNode = ref(null)
    const focusedRow = ref(0)
    const focusedCol = ref(0)
    const sortKey = ref('')
    const sortOrder = ref(ARIA_SORT_ORDER['ASCENDING'])
    const sortBy = (key: string): void => {
      if (sortKey.value === key) {
        if (sortOrder.value === ARIA_SORT_ORDER['ASCENDING']) {
          sortOrder.value = ARIA_SORT_ORDER['DESCENDING']
        } else {
          sortOrder.value = ARIA_SORT_ORDER['ASCENDING']
        }
      } else {
        sortKey.value = key
        sortOrder.value = ARIA_SORT_ORDER['ASCENDING']
      }
      emit('update:sort-key', sortKey.value)
      emit('update:sort-order', sortOrder.value)
    }
    const colSortOrder = (key: string): string =>
      sortKey.value === key ? sortOrder.value : ARIA_SORT_ORDER['NONE']
    const getAriaLabelledby = computed(() =>
      props.ariaLabel ? false : props.ariaLabelledby,
    )
    const isValidCell = (row, col) =>
      !isNaN(row) &&
      !isNaN(col) &&
      row >= 0 &&
      col >= 0 &&
      dataGrid.value &&
      dataGrid.value.rows.length &&
      row < dataGrid.value.rows.length &&
      col < dataGrid.value.rows[row].children.length

    const getNextCell = (currRow, currCol, directionX, directionY) => {
      let row = currRow + directionY
      let col = currCol + directionX
      const rowCount = dataGrid.value.rows.length
      const isLeftRight = directionX !== 0
      const colCount = dataGrid.value.rows[0].children.length

      if (!rowCount) {
        return false
      }

      if (isLeftRight) {
        if (col < 0) {
          col = colCount - 1
          row--
        }

        if (col >= colCount) {
          col = 0
          row++
        }
      }

      if (!isLeftRight) {
        if (row < 0) {
          col--
          row = rowCount - 1
          if (
            dataGrid.value.rows[row] &&
            col >= 0 &&
            !dataGrid.value.rows[row].children[col]
          ) {
            row--
          }
        } else if (row >= rowCount || !dataGrid.value.rows[row].children[col]) {
          row = 0
          col++
        }
      }

      if (row === 0 && !props.focusableHeadCells) {
        return false
      }
      if (row > 0 && !props.focusableRowCells) {
        return false
      }
      if (isValidCell(row, col)) {
        return {
          row: row,
          col: col,
        }
      } else if (isValidCell(currRow, currCol)) {
        return {
          row: currRow,
          col: currCol,
        }
      } else {
        return false
      }
    }
    const getNextVisibleCell = (directionX: number, directionY: number) => {
      let nextCell = getNextCell(
        focusedRow.value,
        focusedCol.value,
        directionX,
        directionY,
      )

      if (!nextCell) {
        return false
      }

      return nextCell
    }
    const getGridCellNode = (row: number, col: number): HTMLElement => {
      const node = dataGrid.value.rows[row].children[col]

      if (node.classList.contains('c-data-grid__th')) {
        return node.firstChild
      }
      return node
    }
    const focusGridNode = (gridNode: HTMLElement) => {
      gridNode.focus()
    }
    const onKeyDown = (ev: KeyboardEvent) => {
      let nextCell
      const old = gridCurrentNode.value
      const keys = [
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Home',
        'End',
      ]

      if (keys.some((key) => key === ev.key)) {
        ev.preventDefault()
      } else {
        return
      }

      if (ev.key === keys[0]) {
        nextCell = getNextVisibleCell(0, -1)
      }
      if (ev.key === keys[1]) {
        nextCell = getNextVisibleCell(0, 1)
      }
      if (ev.key === keys[2]) {
        nextCell = getNextVisibleCell(-1, 0)
      }
      if (ev.key === keys[3]) {
        nextCell = getNextVisibleCell(1, 0)
      }
      if (ev.key === keys[4]) {
        nextCell = {
          row: focusedRow.value,
          col: 0,
        }
        if (ev.ctrlKey) {
          if (props.focusableHeadCells) {
            nextCell.row = 0
          } else {
            nextCell.row = 1
          }
        }
      }
      if (ev.key === keys[5]) {
        nextCell = {
          row: focusedRow.value,
          col:
            dataGrid.value.rows[dataGrid.value.rows.length - 1].children
              .length - 1,
        }
        if (ev.ctrlKey) {
          if (props.focusableRowCells) {
            nextCell.row = dataGrid.value.rows.length - 1
          }
        }
      }
      if (nextCell) {
        gridCurrentNode.value = getGridCellNode(nextCell.row, nextCell.col)
        focusGridNode(gridCurrentNode.value)
        updateFocusState(nextCell.row, nextCell.col)
        updateTabIndex(old)
      }
    }
    const updateFocusState = (row, col) => {
      focusedRow.value = row
      focusedCol.value = col
    }
    const updateTabIndex = (old) => {
      if (old) {
        old.tabIndex = -1
      }
      gridCurrentNode.value.tabIndex = 0
    }
    const onClickNode = (row, col) => {
      const old = gridCurrentNode.value
      gridCurrentNode.value = getGridCellNode(row, col)
      focusGridNode(gridCurrentNode.value)
      updateFocusState(row, col)
      updateTabIndex(old)
    }
    const onClickRow = (rowData) => {
      emit('row-selected', rowData)
    }

    onMounted(() => {
      if (props.initialSortKey) {
        gridCurrentNode.value = dataGrid.value.querySelector(
          `.c-data-grid__th--${props.initialSortKey} span`,
        )

        sortKey.value = props.initialSortKey
        gridCurrentNode.value.tabIndex = 0
      }
    })

    return {
      sortKey,
      sortOrder,
      slots,
      getAriaLabelledby,
      dataGrid,
      sortBy,
      colSortOrder,
      onKeyDown,
      onClickNode,
      onClickRow,
    }
  },
})
