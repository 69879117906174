import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-image__wrapper"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "c-image__caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-image", { [`c-image--size-${_ctx.imageSize}`]: _ctx.imageSize }])
  }, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            ref: "imgTag",
            src: _ctx.image.url,
            alt: _ctx.image.altText,
            class: "c-image__image"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.data && _ctx.data.content && _ctx.data.content.caption)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.data.content.caption), 1))
      : _createCommentVNode("", true)
  ], 2))
}