
import { defineComponent } from 'vue'
import TheHeading from '@/components/TheHeading.vue'
import ContentToC from '@/components/ContentToC.vue'
import ElementAudio from '@/components/Elements/audio.vue'
import ElementRelatedResourceCollection from '@/components/Elements/related-resource-collection.vue'
import ElementText from '@/components/Elements/text.vue'
import ElementImage from '@/components/Elements/image.vue'
import ElementFile from '@/components/Elements/file.vue'
import ElementMAuthor from '@/components/Elements/mauthor.vue'
import ElementVideo from '@/components/Elements/video.vue'
import ElementSection from '@/components/Elements/section.vue'

export default defineComponent({
  name: 'ContentRenderer',

  props: {
    title: String,
    contentItems: Array,
    hasToc: Boolean,
  },

  components: {
    TheHeading,
    ContentToC,
    ElementAudio,
    ElementRelatedResourceCollection,
    ElementText,
    ElementImage,
    ElementFile,
    ElementMAuthor,
    ElementVideo,
    ElementSection,
  },

  setup() {
    const mapComponent = (type: string): string => {
      const types = {
        video: 'ElementVideo',
        image: 'ElementImage',
        text: 'ElementText',
        mauthor: 'ElementMAuthor',
        h5p: 'ElementH5P',
        file: 'ElementFile',
        'related-resource-collection': 'ElementRelatedResourceCollection',
        section: 'ElementSection',
        audio: 'ElementAudio',
      }

      return types[type]
    }

    return {
      mapComponent,
    }
  },
})
