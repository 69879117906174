
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useTenant } from '@/core'
import { ConfigMenuItem } from 'types/interface/Config'
import { useHead } from '@vueuse/head'
import useAccessCheck from '@/composables/useAccessCheck'
import Card from '@/components/Card.vue'
import TheHeading from '@/components/TheHeading.vue'
import ButtonSources from '@/components/ButtonSources.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Card,
    TheHeading,
    ButtonSources,
  },
  setup() {
    const { locale, t } = useI18n()
    const { tenant } = useTenant()
    const menu = computed(() => tenant.value?.menu[locale.value])
    const route = useRoute()
    const { showProduct, productsAvailable, productsChecked } = useAccessCheck()

    const cardActions = {
      type: 'tile',
      action: (child: ConfigMenuItem) => {
        return `${child.to}`
      },
    }

    useHead({
      title: computed(() => `${t('VIEW_HOME')} - ${tenant.value.name}`),
    })

    return {
      menu,
      showProduct,
      route,
      cardActions,
      productsAvailable,
      productsChecked,
    }
  },
})
